import { FormArrayItemProperties } from '@interfaces/form-array-item-properties.interface';
import { PartialFormFileParams } from '@interfaces/forms.interface';
import { Voucher } from '@interfaces/voucher.interface';

const pageKey = 'EMPLOYER.INFO.FAMILY';
export const FAMILY_ALLOWANCE_CONTENT = {
  familyAllowanceSection: {
    headerTitle: `${pageKey}.ALLOWANCE.HEADER_TITLE`,
    headerValue: `${pageKey}.ALLOWANCE.HEADER_VALUE`,
  },
  monthlyCaps: {
    headerTitle: `${pageKey}.CAPS.HEADER_TITLE`,
    headerValue: `${pageKey}.CAPS.HEADER_VALUE`,
  },
};

export const FAMILY_CHARGUE_PROPERTIES: Array<FormArrayItemProperties> = [
  { name: 'fullName', translated: true, style: ['bold'] },
  { name: 'rut', translated: true },
  { name: 'formatedBirthDate', translated: true },
  { name: 'relationship', translated: true, style: ['bold', 'justify-right'] },
  { name: 'requestTypeId', translated: false, style: ['bold', 'justify-right'], },
];

export const FAMILY_ALLOWANCE_FORM_TYPE = '30';

const textFamily = 'AFFILIATE.FAMILY_ASIGNATION';

export const FAMILY_ASIGNATION_VOUCHER: Voucher = {
  title: `${textFamily}.VOUCHER.SUCCESS.TITLE`,
  description: `${textFamily}.VOUCHER.SUCCESS.DESCRIPTION`,
  homeButton: `${textFamily}.VOUCHER.SUCCESS.BUTTON`,
  download: `${textFamily}.VOUCHER.SUCCESS.DOWNLOAD_PDF`
};

export const NOT_PLAN_VITAL_PENSIONER_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_PLAN_VITAL.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_PLAN_VITAL.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_PLAN_VITAL.BUTTON`,
  primaryCallback: () => {
    window.location.href = 'https://traspaso.planvital.cl/';
  },
  iconName: 'i-group-red',
  largeButton: true,
};

export const NOT_PENSIONER_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_PENSIONER.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_PENSIONER.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_PENSIONER.BUTTON`,
  iconName: 'i-warning'
};

export const ON_GOING_PROCEDURE_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.ON_GOING_PROCEDURE.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.ON_GOING_PROCEDURE.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.ON_GOING_PROCEDURE.BUTTON`,
  iconName: 'i-warning'
};

export const PARTIAL_ON_GOING_PROCEDURE_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.PARTIAL_ON_GOING_PROCEDURE.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.PARTIAL_ON_GOING_PROCEDURE.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.PARTIAL_ON_GOING_PROCEDURE.BUTTON`,
  iconName: 'i-warning'
};

export const DUPLICATED_CHARGUE_MODAL_PROPS = {
  title: `${textFamily}.STEPS.FAMILY_CHARGUE.DUPLICATED_CHARGUE.TITLE`,
  description: `${textFamily}.STEPS.FAMILY_CHARGUE.DUPLICATED_CHARGUE.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.FAMILY_CHARGUE.DUPLICATED_CHARGUE.BUTTON`,
  iconName: 'i-warning',
};

export const HELP_MODAL_PROPS = {
  title: `${textFamily}.HELP.MODAL.TITLE`,
  description: `${textFamily}.HELP.MODAL.DESCRIPTION`,
  firstBtnText: `${textFamily}.HELP.MODAL.BUTTON`,
  secondBtnText: `${textFamily}.HELP.MODAL.CANCEL`,
  iconName: 'i-new-vitalia',
  showSecondaryButton: true,
  largeButton: true,
  primaryCallback: () => { },
  secondaryCallback: () => { },
};

export const MORE_THAN_24_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.MORE_THAN_24.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.MORE_THAN_24.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.MORE_THAN_24.BUTTON`,
  iconName: 'i-warning-new',
};

export const MORE_THAN_18_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.MORE_THAN_18.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.MORE_THAN_18.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.MORE_THAN_18.BUTTON`,
  iconName: 'i-warning-new',
};

export const MALE_SPOUSE_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.MALE_SPOUSE.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.MALE_SPOUSE.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.MALE_SPOUSE.BUTTON`,
  iconName: 'i-warning-new',
};

export const OLD_MARRIAGE_CERTIFICATE_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_MARRIAGE_CERTIFICATE.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_MARRIAGE_CERTIFICATE.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_MARRIAGE_CERTIFICATE.BUTTON`,
  iconName: 'i-warning-new',
};

export const OLD_MARRIAGE_OR_DISABILITY_CERT_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_MARRIAGE_OR_DISABILITY.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_MARRIAGE_OR_DISABILITY.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_MARRIAGE_OR_DISABILITY.BUTTON`,
  iconName: 'i-warning-new',
};

export const OLD_WIDOW_CERT_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_WIDOW_CERT.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_WIDOW_CERT.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_WIDOW_CERT.BUTTON`,
  iconName: 'i-warning-new',
};

export const OLD_BIRTH_CERTIFICATE_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_BIRTH_CERTIFICATE.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_BIRTH_CERTIFICATE.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_BIRTH_CERTIFICATE.BUTTON`,
  iconName: 'i-warning-new',
};

export const OLD_DISABILITY_CERTIFICATE_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_DISABILITY_CERTIFICATE.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_DISABILITY_CERTIFICATE.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.OLD_DISABILITY_CERTIFICATE.BUTTON`,
  iconName: 'i-warning-new',
};

export const NOT_ON_GOING_MODAL_PROPS = {
  title: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_ON_GOING.TITLE`,
  description: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_ON_GOING.DESCRIPTION`,
  firstBtnText: `${textFamily}.STEPS.VALIDATION.MODAL.NOT_ON_GOING.BUTTON`,
  iconName: 'i-warning-new',
};

export const CLIENT_CHECK = {
  label: `${textFamily}.STEPS.VALIDATION.CLIENT_CHECK.LABEL`,
  buttonText: `${textFamily}.STEPS.VALIDATION.CLIENT_CHECK.BUTTONTEXT`,
  informationAboutRut: `${textFamily}.STEPS.VALIDATION.CLIENT_CHECK.INFORMATION_ABOUT_RUT`,
  successfulValidation: {
    title: `${textFamily}.STEPS.VALIDATION.CLIENT_CHECK.SUCCESSFUL_VALIDATION.TITLE`,
    describeText: `${textFamily}.STEPS.VALIDATION.CLIENT_CHECK.SUCCESSFUL_VALIDATION.DESCRIBE_TEXT.`
  }
};

export const FAMILY_ALLOWANCE_TOOLTIPS = {
  definitions:
    [{
      title: `${textFamily}.TOOLTIP_TITLE`,
      definition: `${textFamily}.TOOLTIP_DESCRIPTION`
    }]

};

export const REQUIRED_FILE_ASIGNATION = {
  files: [
    {
      buttonLabel: 'Cédula de identidad del afiliado',
      fileIndex: 'affiliateId',
      order: 1,
      required: true
    }
  ]
};

export const FAMILY_ALLOWANCE_PARTIAL_REQUEST_SAVED = {
  title: `MODALS.SAVED.TITLE`,
  description: `MODALS.SAVED.DESCRIPTION`,
  firstBtnText: `MODALS.SAVED.BUTTON`,
  iconName: 'i-saved',
};

export const CHARGUE_RELATIONSHIPS = [
  {
    id: 1,
    description: 'Cónyuge'
  },
  {
    id: 2,
    description: 'Madre viuda '
  },
  {
    id: 3,
    description: 'Hijo/a'
  },
  {
    id: 4,
    description: 'Nieto'
  },
  {
    id: 5,
    description: 'Niño cuidado personal menor'
  }
];

const textFamilycharguesFiles = `${textFamily}.STEPS.FAMILY_CHARGUE.FILES`;

export const REQUIRED_FILES = [
  {
    order: 1,
    buttonLabel: `${textFamilycharguesFiles}.MARIAGE_CERTIFICATE`,
    fileIndex: 'mariageCertificate',
    relationships: [1, 2, 3]
  },
  {
    order: 2,
    buttonLabel: `${textFamilycharguesFiles}.BIRTH_CERTIFICATE`,
    fileIndex: 'birthCertificate',
    relationships: [3, 4, 5, 6, 7, 8, 9]
  },
  {
    order: 3,
    buttonLabel: `${textFamilycharguesFiles}.DEATH_CERTIFICATE`,
    fileIndex: 'deathCertificate',
  },
  {
    order: 4,
    buttonLabel: `${textFamilycharguesFiles}.AFP_CONTRIBUTIONS_CERTIFICATE`,
    fileIndex: 'afpContributionsCertificate',
  },
  {
    order: 5,
    buttonLabel: `${textFamilycharguesFiles}.DISABILITY_RESOLUTION`,
    fileIndex: 'disabilityResolution',
  },
  {
    order: 6,
    buttonLabel: `${textFamilycharguesFiles}.SOCIAL_EXPENSES_REPORT`,
    fileIndex: 'socialExpensesReport',
  },
  {
    order: 6,
    buttonLabel: `${textFamilycharguesFiles}.REGULAR_STUDENT_CERTIFICATE`,
    fileIndex: 'studentCertificate',
  },
  {
    order: 7,
    buttonLabel: `${textFamilycharguesFiles}.COURT_SENTENCE`,
    fileIndex: 'courtSentence',
  },
  {
    order: 8,
    buttonLabel: `${textFamilycharguesFiles}.AFFIDAVIT_OF_SINGLENESS`,
    fileIndex: 'affidavitOfSingleness',
  },
  {
    order: 9,
    buttonLabel: `${textFamilycharguesFiles}.WORK_CONTRACT`,
    fileIndex: 'workContract',
  },
  {
    order: 10,
    buttonLabel: `${textFamilycharguesFiles}.SII_CERTIFICATE`,
    fileIndex: 'siiCertificate',
  },
];

export const FAMILY_CHARGUE_CONTENT = {
  steps: {
    validation: {
    },
    confirmation: {
      title: `${textFamily}.STEPS.CONFIRMATION.TITLE`,
      description: `${textFamily}.STEPS.CONFIRMATION.DESCRIPTION`,
    }
  }
};

export const FORM_FILE_PARAMS: PartialFormFileParams = {
  keys: [],
  personRut: [],
  actions: [],
};

export const FAMILY_ASIGNATION_STEPS = [
  `AFFILIATE.FAMILY_ASIGNATION.STEPS.VALIDATION.LABEL`,
  `AFFILIATE.FAMILY_ASIGNATION.STEPS.RECORDS.LABEL`,
  `AFFILIATE.FAMILY_ASIGNATION.STEPS.FAMILY_CHARGUE.LABEL`,
  `AFFILIATE.FAMILY_ASIGNATION.STEPS.CONFIRMATION.LABEL`,
  `AFFILIATE.FAMILY_ASIGNATION.STEPS.VOUCHER.LABEL`
];

export const TEMPORARY_OBJECT = {
  rut: '1-9',
  name: 'Steven',
  secondSurname: 'Espinoza',
  email: 'stevenee13@gmail.com',
  cellphone: '932890343',
  address: 'calle numero 10',
  region: '1',
  commune: '1402',
  lastName: 'DosReis',
  birthdate: '2024-06-25',
};

export const TEMPORARY_OBJECT2 = {
  operation: 'add',
  requestType: 'add',
  name: 'Steven',
  dirtyRut: '263298845',
  startDate: '2024-06-25',
  lastName: 'asd',
  secondSurname: 'Enrique',
  rut: '26.329.884-5',
  birthdate: '2024-06-25',
  begindate: '2024-06-25',
  gender: 'F',
  relationshipId: 3,
  isDisabled: true,
  genericFirstDate: '2024-06-25',
  genericSecondDate: '2024-06-25',
  attachedFiles: [
    {},
    {},
    {}
  ],
  relationship: 'Hijo/a',
  fullName: 'Steven Espinoza DosReis',
  style: 'bold',
  fileActions:
  {
    keyList: ['workContract', 'siiCertificate', 'key'],
    fileList: ['file', 'file', 'file'],
    actionList: []
  }

};

const errorsBackgroundFieldsKey = `GENERAL_BACKGROUND_FIELDS.ERRORS`;
const textFamilyModal = `${textFamily}.STEPS.FAMILY_CHARGUE.MODAL`;

export const ALLOWANCE_MODAL_CONTENT = {
  title: `${textFamilyModal}.TITLE`,
  secondTitle: `${textFamilyModal}.SECOND_TITLE`,
  personalData: {
    title: `${textFamilyModal}.DESCRIPTION`,
    form: {
      rut: `${textFamilyModal}.FORM.RUT`,
      name: `${textFamilyModal}.FORM.NAME`,
      lastName: `${textFamilyModal}.FORM.LAST_NAME`,
      secondSurname: `${textFamilyModal}.FORM.SECOND_SURNAME`,
      birthdate: `${textFamilyModal}.FORM.BIRTHDATE`,
      beginDate: `${textFamilyModal}.FORM.BEGIN_DATE`,
      gender: {
        label: `${textFamilyModal}.FORM.GENDER.LABEL`,
        male: `${textFamilyModal}.FORM.GENDER.MALE`,
        female: `${textFamilyModal}.FORM.GENDER.FEMALE`,
      },
      chargue: {
        add: `${textFamilyModal}.FORM.CHARGUE.ADD`,
        modify: `${textFamilyModal}.FORM.CHARGUE.MODIFY`,
        delete: `${textFamilyModal}.FORM.CHARGUE.DELETE`
      },
      disclaimerBoxWife: `${textFamilyModal}.FORM.DISCLAIMER_BOX.WIFE`,
      disclaimerBoxHusband: `${textFamilyModal}.FORM.DISCLAIMER_BOX.HUSBAND`,
      disclaimerBoxSon: `${textFamilyModal}.FORM.DISCLAIMER_BOX.SON`,
      disclaimerBoxAdultSon: `${textFamilyModal}.FORM.DISCLAIMER_BOX.ADULT_SON`,
      disclaimerBoxGrandSon: `${textFamilyModal}.FORM.DISCLAIMER_BOX.GRAND_SON`,
      disclaimerBoxAdultGrandSon: `${textFamilyModal}.FORM.DISCLAIMER_BOX.ADULT_GRAND_SON`,
      disclaimerBoxCareChild: `${textFamilyModal}.FORM.DISCLAIMER_BOX.CARE_CHILD`,
      relationship: `${textFamilyModal}.FORM.RELATIONSHIP`,
      isDisabled: `${textFamilyModal}.FORM.IS_DISABLED`,
      benefitStart: `${textFamilyModal}.FORM.BENEFIT_START`,
      mariageCertificateDate: `${textFamilyModal}.FORM.MARIAGE_CERTIFICATE_DATE`,
      disabilityCertificateDate: `${textFamilyModal}.FORM.DISABILITY_CERTIFICATE_DATE`,
      disclaimerBoxWidow: `${textFamilyModal}.FORM.DISCLAIMER_BOX.WIDOW`,
      motherMariageCertificateDate: `${textFamilyModal}.FORM.MOTHER_MARIAGE_CERTIFICATE_DATE`,
      motherHusbandDeathCertificateDate: `${textFamilyModal}.FORM.MOTHER_HUSBAND_DEATH_CERTIFICATE_DATE`,
      birthCertificateDate: `${textFamilyModal}.FORM.BIRTH_CERTIFICATE_DATE`,
    }
  },
  contactData: {
    title: `${textFamilyModal}.CONTACT_DATA.TITLE`,
    form: {
      email: `${textFamilyModal}.CONTACT_DATA.FORM.EMAIL`,
      cellphone: `${textFamilyModal}.CONTACT_DATA.FORM.CELLPHONE`,
      reEmail: `${textFamilyModal}.CONTACT_DATA.FORM.RE_EMAIL`
    }
  },
  form: {
    positiveAnswer: `${textFamilyModal}.FORM.POSITIVE_ANSWER`,
    negativeAnswer: `${textFamilyModal}.FORM.NEGATIVE_ANSWER`,
  },
  errors: {
    required: `${textFamilyModal}.ERRORS.REQUIRED`,
    invalidRut: `${textFamilyModal}.ERRORS.INVALID_RUT`,
    invalidCellphone: `${textFamilyModal}.ERRORS.INVALID_CELLPHONE`,
    invalidEmail: `${textFamilyModal}.ERRORS.INVALID_EMAIL`,
    invalidDomain: `${textFamilyModal}.ERRORS.INVALID_DOMAIN`,
    mustMatch: `${textFamilyModal}.ERRORS.MUST_MATCH`,
    patternLetters: `${errorsBackgroundFieldsKey}.PATTERN_LETTERS`,
  },
  buttons: {
    addBeneficiary: `${textFamilyModal}.BUTTONS.ADD`,
    updateBeneficiary: `${textFamilyModal}.BUTTONS.MODIFY`
  },
  tutorData: {
    title: `${textFamilyModal}.TUTOR_DATA.TITLE`,
    isMandataryTutor: `${textFamilyModal}.TUTOR_DATA.RESPONSABLE_TUTOR`,
  }
};


