import { Component, Input, OnInit } from '@angular/core';
import { EMPLOYER_DISCOUNT_REQUEST_CONTENT } from '@constants/pages-content/employer-discount.constant';
import { FontService } from '@providers/font/font.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-form-list-confirm-expandable',
  templateUrl: './form-list-confirm-expandable.component.html',
  styleUrls: ['./form-list-confirm-expandable.component.scss'],
})
export class FormListConfirmExpandableComponent<T> implements OnInit {
  @Input() public pageContent: any;
  @Input() public properties: any;
  public regions: any;
  public region: any;
  public isOpen = false;
  public emplouerInfo = EMPLOYER_DISCOUNT_REQUEST_CONTENT;

  public get itemsCount(): number {
    return this.properties.length;
  }

  constructor(
    public font: FontService,
    public utils: Utils,
    public parametersService: ParametersService
  ) { }

  ngOnInit() {
    this.getRegions();
    if (this.properties && this.properties.length > 0) {
      this.properties[0].isOpen = true;
    }
  }

  public getRegions() {
    this.parametersService.getRegions().subscribe(regions => {
      this.regions = regions;
    });
  }

  public getRegion(code: string = '') {
    if (code && this.regions) {
      const region = this.regions.find(item => item.code === code);
      this.region = region;
      return region;
    }
  }

  public getCommune(code: string = '') {
    if (code && this.region) {
      return this.region.communes.find(item => item.code === code);
    }
  }

  public getRequestType(key) {

    switch (key) {
      case 'authorization':
        return this.emplouerInfo.steps.backgrounds.form.fields.requestType.option1;
      case 'modification':
        return this.emplouerInfo.steps.backgrounds.form.fields.requestType.option2;
      case 'revocation':
        return this.emplouerInfo.steps.backgrounds.form.fields.requestType.option3;
      default:
        return;
    }
  }

  public getContributionType(key) {

    switch (key) {
      case 'money':
        return this.emplouerInfo.steps.authorization.form.fields.contributionsIn.option1;
      case 'rim':
        return this.emplouerInfo.steps.authorization.form.fields.contributionsIn.option2;
      // case 'uf':
      //   return this.emplouerInfo.steps.authorization.form.fields.contributionsIn.option3;
      default:
        return;
    }
  }

  public getValidityType(key) {

    switch (key) {
      case 'temporary':
        return this.emplouerInfo.steps.authorization.form.fields.discountAuthorizationValidy.option1;
      case 'undefined':
        return this.emplouerInfo.steps.authorization.form.fields.discountAuthorizationValidy.option2;
      default:
        return;
    }
  }

  public getFundType(key) {

    switch (key) {
      case 'fundA':
        return this.emplouerInfo.steps.authorization.form.fields.fundType.option1;
      case 'fundB':
        return this.emplouerInfo.steps.authorization.form.fields.fundType.option2;
      case 'fundC':
        return this.emplouerInfo.steps.authorization.form.fields.fundType.option3;
      case 'fundD':
        return this.emplouerInfo.steps.authorization.form.fields.fundType.option4;
      default:
        return;
    }
  }

  public getTranslate(key: string, control: string): string {
    switch (control) {
      case 'region':
        return this.getRegion(key) ? this.getRegion(key).description : key;
      case 'commune':
        return this.getCommune(key) ? this.getCommune(key).description : key;
      case 'gender':
        return key === 'M' ? 'Masculino' : 'Femenino';
      case 'isDisabled':
        return key ? 'Si' : 'No';
      case 'rut':
        return this.utils.rutFormat(key);
      case 'requestType':
        return this.getRequestType(key) ? this.getRequestType(key) : key;
      case 'contributionsIn':
        return this.getContributionType(key) ? this.getContributionType(key) : key;
      case 'discountAuthorizationValidy':
        return this.getValidityType(key) ? this.getValidityType(key) : key;
      case 'fundType':
        return this.getFundType(key) ? this.getFundType(key) : key;
      default:
        return key;
    }
  }

  public toggleInfo(index: number): void {
    this.properties[index].isOpen = !this.properties[index].isOpen;
  }

}
