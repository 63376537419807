import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalContactCenterV2Component } from '@components/modal-contact-center-v2/modal-contact-center-v2.component';
import { ModalGenericComponent } from '@components/modal-generic/modal-generic.component';
import { HELP_MODAL_PROPS } from '@constants/pages-content/family-asignation.constant';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@providers/modal/modal.service';
import { VitaliaService } from '@services/vitalia/vitalia.service';


@Component({
  selector: 'app-header-help-button',
  templateUrl: './header-help.component.html',
  styleUrls: ['./header-help.component.scss'],
})
export class HeaderHelpComponent implements OnInit {
  public showVitaliaIcon: boolean;
  public helpComponent = ModalContactCenterV2Component;
  public textInfo: string;

  @Output() public menuStatus = new EventEmitter<boolean>();

  constructor(
    private modalService: ModalService,
    private vitaliaService: VitaliaService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.vitaliaService.vitaliaStatusObs().subscribe((response: boolean) => {
      this.showVitaliaIcon = response;
    });
  }

  public async helpInitModal(): Promise<void> {
    await this.modalService.openModal(ModalGenericComponent, {
      ...HELP_MODAL_PROPS,
      secondaryCallback: () => {
        this.modalService.closeModals();
        this.help();
      }
    });
  }

  public async help() {
    await this.modalService.openModal(
      this.helpComponent,
      this.translateService.instant(this.textInfo + '.TITLE'),
      'modal-default',
      null
    );
  }

}
