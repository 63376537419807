import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { assessmentPath, pensionerPaths } from '@constants/routes.constant';

const routes: Routes = [
  { path: assessmentPath, redirectTo: 'asesoria/centro-tramites', pathMatch: 'full' },
  {
    path: assessmentPath,
    children: [
      {
        path: pensionerPaths.assessmentPath.paperworkCenterPath,
        loadChildren: () => import('./paperwork-center/paperwork-center.module').then(m => m.PaperworkCenterPageModule)
      },
      {
        path: pensionerPaths.assessmentPath.familyAllowancePath,
        loadChildren: () => import('./family-allowance/family-allowance.module').then(m => m.FamilyAllowancePageModule)
      },
      {
        path: pensionerPaths.assessmentPath.certifyStudiesPath,
        loadChildren: () => import('./certify-studies/certify-studies.module').then(m => m.CertifyStudiesPageModule)
      },
      {
        path: pensionerPaths.assessmentPath.mortuaryQuotaPath,
        loadChildren: () => import('./mortuary-quota/mortuary-quota.module').then(m => m.MortuaryQuotaRequestPageModule)
      },
      {
        path: pensionerPaths.assessmentPath.inheritanceRequestPath,
        loadChildren: () => import('./inheritance-request/inheritance-request.module').then(m => m.InheritanceRequestPageModule)
      },
      {
        path: pensionerPaths.assessmentPath.chargingFeesPath,
        loadChildren: () => import('./charging-fees/charging-fees.module').then(m => m.ChargingFeesPageModule)
      },
      {
        path: pensionerPaths.assessmentPath.stateGuaranteePath,
        loadChildren: () => import('./state-guarantee/state-guarantee.module').then(m => m.StateGuaranteePageModule)
      },
      {
        path: pensionerPaths.assessmentPath.followUpPath,
        loadChildren: () => import('./paperwork-follow-up/paperwork-follow-up.module').then(m => m.PaperworkFollowUpPageModule)
      },
      {
        path: pensionerPaths.assessmentPath.requestDetailPath,
        loadChildren: () => import('./form-status-detail/form-status-detail.module').then(m => m.FormStatusDetailPageModule)
      },
      {
        path: pensionerPaths.assessmentPath.familyAssignation,
        loadChildren: () => import('./family-assignation/family-assignation.module')
          .then(m => m.FamilyAsignationPageModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AssessmentPageRoutingModule { }
