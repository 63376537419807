import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FILE_EXTENSIONS, FILE_MAX_SIZE } from '@constants/forms.constant';
import { Utils } from '@utils/utils';

const utils = new Utils(null);
const validFileExtension = FILE_EXTENSIONS;

const validateForm = (formGroup: UntypedFormGroup, controlNameList: Array<string>, fileIndex: string) => {
  const control = formGroup.controls[fileIndex];
  const fileName = control.value.name;
  const fileNameExtension = fileName.split('.').pop();
  const fileNameWithoutExtension = fileName.substring(0, fileName.length - (fileNameExtension.length + 1));
  const errors = { maxFileSize: false, nameWithoutExtension: false, nameExtension: false, attachDifferentFiles: false };

  let fileSizeCountTemp = 0;
  controlNameList.forEach(controlName => {
    const attachFileEvaluate = formGroup.controls[controlName];
    fileSizeCountTemp += Number(attachFileEvaluate.value.size);
    if (controlName !== fileIndex && fileName === attachFileEvaluate.value.name) { errors.attachDifferentFiles = true; }
  });
  errors.maxFileSize = fileSizeCountTemp > FILE_MAX_SIZE;
  errors.nameWithoutExtension = !utils.onlyLettersAndNumbers(fileNameWithoutExtension);
  errors.nameExtension = !validFileExtension.some(extension => extension.toUpperCase() === fileNameExtension.toUpperCase());

  control.setErrors(null);
  if (errors.maxFileSize || errors.nameWithoutExtension || errors.nameExtension || errors.attachDifferentFiles) {
    control.setErrors(errors);
  }
};

export function fileValidators(nameList: Array<string>): ValidatorFn {
  return (formGroup: UntypedFormGroup): ValidationErrors => {
    const controlNameList = nameList.filter(controlName => {
      const control = formGroup.controls[controlName];
      if (control && control.value !== '') { return controlName; }
    });
    controlNameList.forEach(controlName => {
      validateForm(formGroup, controlNameList, controlName);
    });
    return;
  };
}

export function attachFileCount(minValue: number): ValidatorFn {
  return (control) => {
    if (!control) { return; }
    if (control.value.length >= minValue) { return null; }
    return { fileCount: true };
  };
}
