import { Component, Input, OnInit } from '@angular/core';
import { FontService } from '@providers/font/font.service';
import { ParametersService } from '@services/parameters/parameters.service';
import { Utils } from '@utils/utils';

@Component({
  selector: 'app-form-list-confirm',
  templateUrl: './form-list-confirm.component.html',
  styleUrls: ['./form-list-confirm.component.scss'],
})
export class FormListConfirmComponent<T> implements OnInit {
  @Input() public pageContent: any;
  @Input() public properties: any;
  public regions: any;
  public region: any;


  public get itemsCount(): number {
    return this.properties.length;
  }

  constructor(
    public font: FontService,
    public utils: Utils,
    public parametersService: ParametersService
  ) {

  }

  ngOnInit() {
    this.getRegions();
  }

  public getRegions() {
    this.parametersService.getRegions().subscribe(regions => {
      this.regions = regions;
    });
  }

  public getRegion(code: string = '') {
    if (code && this.regions) {
      const region = this.regions.find(item => item.code === code);
      this.region = region;
      return region;
    }
  }

  public getCommune(code: string = '') {
    if (code && this.region) {
      return this.region.communes.find(item => item.code === code);
    }
  }

  public getTranslate(key: string, control: string): string {
    switch (control) {
      case 'region':
        return this.getRegion(key) ? this.getRegion(key).description : key;
      case 'commune':
        return this.getCommune(key) ? this.getCommune(key).description : key;
      case 'gender':
        return key === 'M' ? 'Masculino' : 'Femenino';
      case 'isDisabled':
        return key ? 'Si' : 'No';
      // case 'birthdate':
      //   const date = new Date(key);
      //   return key ? this.utils.getFormattedDate(date, 'dd-MM-yyyy') : 'No';
      default:
        return key;
    }
  }
}
