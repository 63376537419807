<div class="modal modal-information-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="information-content modal-frame">
    <div class="title" [ngClass]="font?.size" [innerHTML]="information?.title | translate"></div>
    <div class="description dynamic-text tertiary" [ngClass]="font?.size"></div>
    <b class="centered-container sub-title">{{modalInformation+'.IMPORTANT_INFO' | translate}}</b>
    <br>
    <div class="gray-alert">
      <div class="icon-alert">
        <span class="number-card">1</span>
        <p>{{modalInformation+'.STEP_ONE' |
          translate}}</p>
      </div>
    </div>
    <div class="gray-alert">
      <div class="icon-alert">
        <span class="number-card">2</span>
        <p>{{modalInformation+'.STEP_TWO' |
          translate}}</p>
      </div>
    </div>
    <div class="gray-alert">
      <div class="icon-alert">
        <span class="number-card">3</span>
        <p>{{modalInformation+'.STEP_THREE' |
          translate}}</p>
      </div>
    </div>
    <div class="pt-5">
      <p class="align" [innerHTML]="modalInformation+'.MORE_INFO' | translate"></p>
    </div>
    <div class="pt-5">
    </div>
    <div class="centered-container">
      <ion-button class="button primary back" [ngClass]="font?.size" (click)="goTo()">{{modalInformation +
        '.BUTTON_ACTION' | translate}}</ion-button>
    </div>
    <div class="centered-container pt-5 mobile" (click)="closeModal()">
      <p class="sub-title mt-25">Volver</p>
    </div>
  </div>