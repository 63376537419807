import { ColumnType } from '@interfaces/table.interface';

const searchPaperwork = 'SEARCH_PAPERWORK';
const tableHeaders = 'SEARCH_PAPERWORK.TABLE_HEADERS';
const validateUser = `${searchPaperwork}.VALIDATE_USER`;
const searchPaperworkOptionsKey = `${validateUser}.FORM.OPTIONS`;
const simplyLanguage = `${searchPaperwork}.SIMPLE_LANGUAGE`;
const modalError = `${searchPaperwork}.SEARCH_PROCEDURES_ERROR_MODAL`;

export const PAPERWORK_FOLLOW_UP_PAGE_CONTENT = {
  alert: `${searchPaperwork}.ALERT`,
  title: `${searchPaperwork}.TITLE`,
  subtitle: `${searchPaperwork}.SUBTITLE`,
  tableHeaders: {
    formType: `${tableHeaders}.FORM_TYPE`,
    folio: `${tableHeaders}.FOLIO`,
    date: `${tableHeaders}.DATE`,
    origin: `${tableHeaders}.ORIGIN`,
    status: `${tableHeaders}.STATUS`,
  },
  validateUser: {
    form: {
      rut: `${validateUser}.FORM.RUT`,
      search: `${validateUser}.FORM.SEARCH`,
      options: {
        folio: `${searchPaperworkOptionsKey}.FOLIO`,
        phone: `${searchPaperworkOptionsKey}.PHONE`,
        email: `${searchPaperworkOptionsKey}.EMAIL`,
      }
    },
    buttons: {
      search: `${validateUser}.BUTTONS.SEARCH`,
    },
    errors: {
      required: `${validateUser}.ERRORS.REQUIRED`,
      invalidRut: `${validateUser}.ERRORS.RUT`,
      invalidCellPhone: `${validateUser}.ERRORS.INVALID_CELLPHONE`,
      invalidEmail: `${validateUser}.ERRORS.INVALID_EMAIL`,
    }
  },
  simplyLanguage: {
    status: {
      partial: `${simplyLanguage}.STATUS.P`,
      enter: `${simplyLanguage}.STATUS.I`,
      inCourse: `${simplyLanguage}.STATUS.E`,
      rejected: `${simplyLanguage}.STATUS.R`,
      accepted: `${simplyLanguage}.STATUS.A`,
      inProcess: `${simplyLanguage}.STATUS.T`,
      expired: `${simplyLanguage}.STATUS.C`,
      pendingDocuments: `${simplyLanguage}.STATUS.D`,
      canceled: `${simplyLanguage}.STATUS.N`
    },
    formType: {
      mortuaryQuota: `${simplyLanguage}.FORM_TYPE.MORTUARY_QUOTA`,
      survivalPension: `${simplyLanguage}.FORM_TYPE.SURVIVAL_PENSION`,
      inheritanceRequest: `${simplyLanguage}.FORM_TYPE.INHERITANCE_REQUEST`,
      surplusPayment: `${simplyLanguage}.FORM_TYPE.SURPLUS_PAYMENT`,
      surplusCalculation: `${simplyLanguage}.FORM_TYPE.SURPLUS_CALCULATION`,
      elderhoodPension: `${simplyLanguage}.FORM_TYPE.ELDERHOOD_PENSION`,
      anticipatedElderhoodPension: `${simplyLanguage}.FORM_TYPE.ANTICIPATED_ELDERHOOD_PENSION`,
      disabilityPension: `${simplyLanguage}.FORM_TYPE.DISABILITY_PENSION`,
      disabilityReevaluation: `${simplyLanguage}.FORM_TYPE.DISABILITY_REEVALUATION`,
      overpaymentRefund: `${simplyLanguage}.FORM_TYPE.OVERPAYMENT_REFUND`,
      foreignerTechnicalWithdrawal: `${simplyLanguage}.FORM_TYPE.FOREIGNER_TECHNICAL_WITDRAWAL`,
      caiForm: `${simplyLanguage}.FORM_TYPE.CAI_FORM`,
      familyAllowanceFormFileType: `${simplyLanguage}.FORM_TYPE.FAMILY_ALLOWANCE`,
      chargingFeesFormFileType: `${simplyLanguage}.FORM_TYPE.CHARGING_FEES`,
      studiesAccreditation: `${simplyLanguage}.FORM_TYPE.STUDIES_ACREDITATION`,
      employerDiscountForVoluntaryAffiliateFormType: `${simplyLanguage}.FORM_TYPE.EMPLOYER_DISCOUNT_VOLUNTARY_MEMBER`,
      requestForTransferFundsFromIndividualAccountFormFileType:
        `${simplyLanguage}.FORM_TYPE.REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT`,
      requestSurplusFundsSimulatorFormFileType: `${simplyLanguage}.FORM_TYPE.REQUEST_SURPLUS_FUNDS_SIMULATOR`,
      additionalPensionApplicationFormFileType: `${simplyLanguage}.FORM_TYPE.ADDITIONAL_PENSION_APPLICATION`,
      depositIpsFormFileType: `${simplyLanguage}.FORM_TYPE.DEPOSIT_IPS_FORM_FILE_TYPE`,
      mandatesFormFileType: `${simplyLanguage}.FORM_TYPE.MANDATE`,
      lifeAnnuityApplicationFormFileType: `${simplyLanguage}.FORM_TYPE.LIFE_ANNUITY_APPLICATION`,
      lifeAnnuityDecisionFormFileType: `${simplyLanguage}.FORM_TYPE.LIFE_ANNUITY_DECISION`,
      selectModalityBondRecognitionFormFileType: `${simplyLanguage}.FORM_TYPE.SELECT_MODALITY_BOND_RECOGNITION`,
      calculationAndBondRecognitionFormFileType: `${simplyLanguage}.FORM_TYPE.CALCULATION_AND_BOND_RECOGNITION`,
      familyAllowanceAfiliateFormFileType: `${simplyLanguage}.FORM_TYPE.FAMILY_ALLOWANCE_AFFILIATE`,
    }
  },
  modalError: {
    title: `${modalError}.TITLE`,
    defaultDescription: `${modalError}.DESCRIPTION`,
    firstBtnText: `${modalError}.BUTTON`
  }
};

export const STATUS_COLOR_AND_SIMPLY_LANGUAGE = [
  { status: 'P', colorClass: 'partial', simplyLanguageStatus: 'partial' },
  { status: 'I', colorClass: 'enter', simplyLanguageStatus: 'enter' },
  { status: 'E', colorClass: 'inCourse', simplyLanguageStatus: 'inCourse' },
  { status: 'R', colorClass: 'rejected', simplyLanguageStatus: 'rejected' },
  { status: 'A', colorClass: 'accepted', simplyLanguageStatus: 'accepted' },
  { status: 'T', colorClass: 'inProcess', simplyLanguageStatus: 'inProcess' },
  { status: 'C', colorClass: 'expired', simplyLanguageStatus: 'expired' },
  { status: 'D', colorClass: 'pendingDocuments', simplyLanguageStatus: 'pendingDocuments' },
  { status: 'N', colorClass: 'canceled', simplyLanguageStatus: 'canceled' },
  { status: 'X', colorClass: 'canceled', simplyLanguageStatus: 'canceled' }
];

export const TABLE_COLUMNS = [
  {
    name: 'formType',
    type: 'string' as ColumnType,
    label: 'formType',
  },
  {
    name: 'date',
    type: 'date' as ColumnType,
    label: 'date',
    sortable: true,
  },
  {
    name: 'origin',
    type: 'string' as ColumnType,
    label: 'origin',
  },
  {
    name: 'simplyLanguageStatus',
    type: 'string' as ColumnType,
    label: 'status'
  },
];
