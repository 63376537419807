const pageKey = 'AFFILIATE.EMPLOYER_DISCOUNT';
const toastKey = `${pageKey}.TOAST`;
const clientCheckKey = `${pageKey}.STEPS.CLIENT_CHECK`;
const backgroundsKey = `${pageKey}.STEPS.BACKGROUNDS`;
const backgroundsFormKey = `${pageKey}.STEPS.BACKGROUNDS.FORM`;
const backgroundsFormFieldsKey = `${pageKey}.STEPS.BACKGROUNDS.FORM.FIELDS`;
const authorizationKey = `${pageKey}.STEPS.AUTHORIZATION`;
const authorizationFormKey = `${pageKey}.STEPS.AUTHORIZATION.FORM`;
const authorizationFormFieldsKey = `${pageKey}.STEPS.AUTHORIZATION.FORM.FIELDS`;
const employerKey = `${pageKey}.STEPS.EMPLOYER`;
const employerFormKey = `${pageKey}.STEPS.EMPLOYER.FORM`;
const confirmationKey = `${pageKey}.STEPS.CONFIRMATION`;
const saveModal = 'MODALS.SAVED';
export const EMPLOYER_DISCOUNT_REQUEST_CONTENT = {
  title: `${pageKey}.TITLE`,
  toast: {
    message: `${toastKey}.MESSAGE`,
    closeBtn: `${toastKey}.CLOSE_BTN`
  },

  steps: {
    clientCheck: {
      label: `${clientCheckKey}.LABEL`,
      buttonText: `${clientCheckKey}.BUTTONTEXT`,
      informationAboutRut: `${clientCheckKey}.INFORMATION_ABOUT_RUT`,
      successfulValidation: {
        title: `${clientCheckKey}.SUCCESSFUL_VALIDATION.TITLE`,
        describeText: `${clientCheckKey}.SUCCESSFUL_VALIDATION.DESCRIBE_TEXT`
      }
    },
    backgrounds: {
      label: `${backgroundsKey}.LABEL`,
      form: {
        title: `${backgroundsFormKey}.TITLE`,
        inputPlaceholder: `${backgroundsFormKey}.INPUT_PLACEHOLDER`,
        inputPlaceholderEmail: `${backgroundsFormKey}.INPUT_PLACEHOLDER_EMAIL`,
        inputPlaceholderPhone: `${backgroundsFormKey}.INPUT_PLACEHOLDER_PHONE`,
        labelSelect: `${backgroundsFormKey}.LABEL_SELECT`,
        fields: {
          name: `${backgroundsFormFieldsKey}.NAME`,
          fatherLastName: `${backgroundsFormFieldsKey}.FATHERLASTNAME`,
          motherLastName: `${backgroundsFormFieldsKey}.MOTHERLASTNAME`,
          rut: `${backgroundsFormFieldsKey}.RUT`,
          address: `${backgroundsFormFieldsKey}.ADDRESS`,
          email: `${backgroundsFormFieldsKey}.EMAIL`,
          phone: `${backgroundsFormFieldsKey}.PHONE`,
          region: `${backgroundsFormFieldsKey}.REGION`,
          commune: `${backgroundsFormFieldsKey}.COMMUNE`,
          requestType: {
            title: `${backgroundsFormFieldsKey}.REQUEST_TYPE.TITLE`,
            option1: `${backgroundsFormFieldsKey}.REQUEST_TYPE.OPTION1`,
            option2: `${backgroundsFormFieldsKey}.REQUEST_TYPE.OPTION2`,
            option3: `${backgroundsFormFieldsKey}.REQUEST_TYPE.OPTION3`
          }
        },
        errors: {
          required: `${pageKey}.STEPS.BACKGROUNDS.FORM.ERRORS.REQUIRED`,
          invalidRut: `${pageKey}.STEPS.BACKGROUNDS.FORM.ERRORS.INVALID_RUT`,
          invalidCellphone: `${pageKey}.STEPS.BACKGROUNDS.FORM.ERRORS.INVALID_CELLPHONE`,
          invalidEmail: `${pageKey}.STEPS.BACKGROUNDS.FORM.ERRORS.INVALID_EMAIL`,
          invalidDomain: `${pageKey}.STEPS.BACKGROUNDS.FORM.ERRORS.INVALID_DOMAIN`,
          patternLetters: `${pageKey}.STEPS.BACKGROUNDS.FORM.ERRORS.PATTERN_LETTERS`,
          notSameRut: `${pageKey}.STEPS.BACKGROUNDS.FORM.ERRORS.NOT_SAME_RUT`
        }
      }
    },
    authorization: {
      label: `${authorizationKey}.LABEL`,
      form: {
        title: `${authorizationFormKey}.TITLE`,
        fields: {
          contributionsIn: {
            title: `${authorizationFormFieldsKey}.CONTRIBUTIONS_IN.TITLE`,
            label: `${authorizationFormFieldsKey}.CONTRIBUTIONS_IN.LABEL`,
            option1: `${authorizationFormFieldsKey}.CONTRIBUTIONS_IN.OPTION1`,
            option2: `${authorizationFormFieldsKey}.CONTRIBUTIONS_IN.OPTION2`,
          },
          amountDiscount: {
            title: `${authorizationFormFieldsKey}.AMOUNT_DISCOUNT.TITLE`,
            placeholder: `${authorizationFormFieldsKey}.AMOUNT_DISCOUNT.PLACEHOLDER`,
            errorMinsalary: `${authorizationFormFieldsKey}.AMOUNT_DISCOUNT.ERROR_MIN_SALARY`
          },
          percentage: {
            title: `${authorizationFormFieldsKey}.PERCENTAGE.TITLE`,
            placeholder: `${authorizationFormFieldsKey}.PERCENTAGE.PLACEHOLDER`,
          },
          rent: {
            title: `${authorizationFormFieldsKey}.RENT.TITLE`,
            placeholder: `${authorizationFormFieldsKey}.RENT.PLACEHOLDER`
          },
          discountAuthorizationValidy: {
            title: `${authorizationFormFieldsKey}.DISCOUNT_AUTHORIZATION_VALIDY.TITLE`,
            label: `${authorizationFormFieldsKey}.DISCOUNT_AUTHORIZATION_VALIDY.LABEL`,
            option1: `${authorizationFormFieldsKey}.DISCOUNT_AUTHORIZATION_VALIDY.OPTION1`,
            option2: `${authorizationFormFieldsKey}.DISCOUNT_AUTHORIZATION_VALIDY.OPTION2`
          },
          firstMonthDiscount: {
            title: `${authorizationFormFieldsKey}.FIRST_MONTH_DISCOUNT.TITLE`,
            label: `${authorizationFormFieldsKey}.FIRST_MONTH_DISCOUNT.LABEL`,
          },
          firstMonthPayment: {
            title: `${authorizationFormFieldsKey}.FIRST_MONTH_PAYMENT.TITLE`,
            label: `${authorizationFormFieldsKey}.FIRST_MONTH_PAYMENT.LABEL`,
          },
          lastMonthDiscount: {
            title: `${authorizationFormFieldsKey}.LAST_MONTH_DISCOUNT.TITLE`,
            label: `${authorizationFormFieldsKey}.LAST_MONTH_DISCOUNT.LABEL`,
          },
          fundType: {
            title: `${authorizationFormFieldsKey}.FUND_TYPE.TITLE`,
            label: `${authorizationFormFieldsKey}.FUND_TYPE.LABEL`,
            option1: `${authorizationFormFieldsKey}.FUND_TYPE.OPTION1`,
            option2: `${authorizationFormFieldsKey}.FUND_TYPE.OPTION2`,
            option3: `${authorizationFormFieldsKey}.FUND_TYPE.OPTION3`,
            option4: `${authorizationFormFieldsKey}.FUND_TYPE.OPTION4`
          },
          checkAuthorization: {
            description: `${authorizationFormFieldsKey}.CHECK_AUTHORIZATION.DESCRIPTION`
          },
          checkRevocation: {
            description: `${authorizationFormFieldsKey}.CHECK_REVOCATION.DESCRIPTION`
          },
        }
      },
      information: {
        partA: `${authorizationKey}.INFORMATION.PARTA`,
        partB: `${authorizationKey}.INFORMATION.PARTB`
      },
      documentsInfAuth: `${authorizationKey}.DOCUMENTS_INF_AUTH`,
      documentsInfMod: `${authorizationKey}.DOCUMENTS_INF_MOD`,
      documentsInfRev: `${authorizationKey}.DOCUMENTS_INF_REV`
    },
    employer: {
      label: `${employerKey}.LABEL`,
      form: {
        title: `${employerFormKey}.TITLE`,
        employerRut: {
          label: `${employerFormKey}.EMPLOYER_RUT.LABEL`,
          btnLabel: `${employerFormKey}.EMPLOYER_RUT.BTNLABEL`
        }
      }

    },
    confirmation: {
      label: `${confirmationKey}.LABEL`,
      labelConfirmation: `${confirmationKey}.LABEL_CONFIRMATION`,
      labelInformation: `${confirmationKey}.LABEL_INFORMATION`,
    },
  },
  validateUser: {
    form: {
      rut: `${pageKey}.VALIDATEUSER.FORM.RUT`,
    }
  },
  buttons: {
    back: `${pageKey}.BUTTONS.BACK`,
    next: `${pageKey}.BUTTONS.NEXT`,
    saveContinueLater: `${pageKey}.BUTTONS.SAVECONTINUELATER`,
    download: `${pageKey}.BUTTONS.DOWNLOAD`

  },
  errors: {
    required: `${pageKey}.ERRORS.REQUIRED`,
    invalidRut: `${pageKey}.ERRORS.INVALID_RUT`,
    invalidCellphone: `${pageKey}.ERRORS.INVALID_CELLPHONE`,
    invalidEmail: `${pageKey}.ERRORS.INVALID_EMAIL`,
    invalidDomain: `${pageKey}.ERRORS.INVALID_DOMAIN`,
    patternLetters: `${pageKey}.ERRORS.PATTERN_LETTERS`,
    notSameRut: `${pageKey}.ERRORS.NOT_SAME_RUT`,
    minRimPercentage: `${pageKey}.ERRORS.MIN_RIM_PERCENTAGE`,
    minRimSalary: `${pageKey}.ERRORS.MIN_RIM_SALARY`,
  },
  modalRutValidation: {
    title: `${pageKey}.MODAL_RUT_VALIDATION.TITLE`,
    textInfo1: `${pageKey}.MODAL_RUT_VALIDATION.TEXTINFO1`,
    textInfo2: `${pageKey}.MODAL_RUT_VALIDATION.TEXTINFO2`,
    btnSubs: `${pageKey}.MODAL_RUT_VALIDATION.BTNSUBS`,
    transferUrl: 'https://traspaso.planvital.cl/'
  }
};

export const EMPLOYER_DISCOUNT_PARTIAL_REQUEST_SAVED = {
  title: `${saveModal}.TITLE`,
  description: `${saveModal}.DESCRIPTION`,
  firstBtnText: `${saveModal}.BUTTON`,
  iconName: 'i-saved',
};


export const DOCUMENTS_REQUIRED_AUTH = {
  auth: {
    order: 1,
    buttonLabel: `Adjunta autorización`,
    required: true,
    fileIndex: 'authDocument',
  },
  personalDocument: {
    order: 2,
    buttonLabel: `Adjunta cédula`,
    required: true,
    fileIndex: 'personalDocument',
  }
};
export const DOCUMENTS_REQUIRED_UPDATE = {
  update: {
    order: 1,
    buttonLabel: `Adjunta modificación`,
    required: true,
    fileIndex: 'updateDocument',
  },
  personalDocument: {
    order: 2,
    buttonLabel: `Adjunta cédula`,
    required: true,
    fileIndex: 'personalDocument',
  }
};
export const DOCUMENTS_REQUIRED_REVOKE = {
  revoke: {
    order: 1,
    buttonLabel: `Adjunta autorización de revocación`,
    required: true,
    fileIndex: 'revokeDocument',
  },
  personalDocument: {
    order: 2,
    buttonLabel: `Adjunta cédula`,
    required: true,
    fileIndex: 'personalDocument',
  },
};
