import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalGenericComponent } from '@components/modal-generic/modal-generic.component';
import { ModalComponent } from '@components/modal/modal.component';
import {
  GENDERS,
  filesActionType
} from '@constants/forms.constant';
import {
  ALLOWANCE_MODAL_CONTENT, MALE_SPOUSE_MODAL_PROPS, MORE_THAN_18_MODAL_PROPS, MORE_THAN_24_MODAL_PROPS,
  OLD_BIRTH_CERTIFICATE_MODAL_PROPS, OLD_DISABILITY_CERTIFICATE_MODAL_PROPS, OLD_MARRIAGE_CERTIFICATE_MODAL_PROPS,
  OLD_MARRIAGE_OR_DISABILITY_CERT_MODAL_PROPS,
  OLD_WIDOW_CERT_MODAL_PROPS, REQUIRED_FILES
} from '@constants/pages-content/family-asignation.constant';
import { ALPHABETIC_PATTERN } from '@constants/regex.constant';
import { affiliateAssessmentPapework } from '@constants/routes.constant';
import { DefaultResponseItem } from '@interfaces/general.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { ModalService } from '@providers/modal/modal.service';
import { NavigationService } from '@providers/navigation/navigation.service';
import { FormUtils } from '@utils/form.utils';
import { Utils } from '@utils/utils';
import { ValidateRut } from '@validators/rut.validator';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-family-asignation',
  templateUrl: './modal-family-asignation.component.html',
  styleUrls: ['./modal-family-asignation.component.scss'],
})
export class ModalFamilyAsignationComponent extends ModalComponent implements OnInit {
  public pageContent = ALLOWANCE_MODAL_CONTENT;
  public familyBurden: any;
  public form: FormGroup;
  public genders = GENDERS;
  public relationships: Array<DefaultResponseItem> = [];
  public loadData = false;
  public maxDate = new Date();
  private actionFiles = filesActionType.none;
  public keyFiles: any[];
  public isGenderDisabled = false;
  public disclaimerBox: string;
  public genericFirstDateLabel: any;
  public genericSecondDateLabel: any;
  public SelectedRelationship: number;
  public requiredFiles: any;
  public get attachedFiles(): FormArray { return this.form.get('attachedFiles') as FormArray; }
  public get rut(): AbstractControl { return this.form.controls.rut; }
  public get buttonText(): string {
    return this.familyBurden
      ? this.pageContent.buttons.updateBeneficiary
      : this.pageContent.buttons.addBeneficiary;
  }

  public get filesLoaded(): any {
    if (!this.familyBurden) { return null; }
    const files: any = this.familyBurden.fileActions;
    const filesObject = {};
    if (files) {
      files.keyList.forEach((key, index) => {
        filesObject[key] = files.fileList[index];
      });
      return filesObject;
    }

  }

  public get isAtachedfilesValid(): boolean {
    return this.attachedFiles.length === this.requiredFiles.files.length;
  }

  public get relationship(): any {
    return this.relationships;
  }


  constructor(
    public font: FontService,
    private formBuilder: FormBuilder,
    private util: Utils,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    protected modalService: ModalService,
    private navService: NavigationService,
    public formUtils: FormUtils
  ) {
    super(params, modalCtrl);
    this.relationships = this.params.get('relationships');
    this.setFamilyBurden(this.params.get('item'));
    this.loadData = true;
  }

  public ngOnInit(): void {
    this.createForm();
    this.requiredFiles = this.filteredFiles();
    if (this.familyBurden) {
      this.newParams();
    }
  }

  public setFamilyBurden(f) {
    if (f) {
      this.familyBurden = f;
      this.familyBurden.operation = f.requestType;
      this.familyBurden.begindate = f.begindate ? f.begindate : f.benefitStartCessationDate;
      this.familyBurden.genericFirstDate = f.genericFirstDate ? f.genericFirstDate : f.birthCertificateDate;
      if (f.genericFirstDate) {
        this.familyBurden.genericFirstDate = f.genericFirstDate;
      } else {
        this.familyBurden.genericFirstDate = f.maritalCertificateDate ? f.maritalCertificateDate : f.birthCertificateDate;
      }
      if (f.genericSecondDate) {
        this.familyBurden.genericSecondDate = f.genericSecondDate;
      } else {
        this.familyBurden.genericSecondDate = f.disabilityResolutionDate ? f.disabilityResolutionDate : f.spouseDeathCertificateDate;
      }
      const isMinorOrTurned18before31dec = this.isMinorOrTurned18before31dec(f.birthdate);

      this.setDisclaimerBox(f.relationshipId, f.gender, f.isDisabled, isMinorOrTurned18before31dec);
    }
  }

  public filteredFiles(): any {
    const relationshipId = +this.getControl('relationshipId').value;
    const birthdate = this.getControl('birthdate').value;
    const isUnderage = this.isUnderAge(birthdate);

    if (!this.form || !relationshipId) {
      return this.getfilteredFile([0]);
    }

    switch (relationshipId) {
      case 1:
        return this.getfilteredFile([0, 3, 4, 10]);
      case 2:
        return this.getfilteredFile([0, 1, 2, 3, 5]);
      case 3:
        return isUnderage ? this.getfilteredFile([1, 4, 6]) : this.getfilteredFile([1, 4, 6, 8, 9, 10]);
      case 4:
        return isUnderage ? this.getfilteredFile([1, 4, 6, 7]) : this.getfilteredFile([1, 4, 6, 7, 8, 9, 10]);
      case 5:
        return this.getfilteredFile([1, 4, 6, 7]);
      default:
        return this.getfilteredFile([11]);
    }
  }

  public isUnderAge(dateOfBirth: string): boolean {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 < 18;
    }

    return age < 18;
  }

  public isMinorOrTurned18before31dec(fechaNacimiento: any): boolean {
    fechaNacimiento = new Date(fechaNacimiento);
    const anioActual = new Date().getFullYear();
    const finDeAnio = new Date(anioActual, 11, 31);
    const edadFinDeAnio = finDeAnio.getFullYear() - new Date(fechaNacimiento).getFullYear();
    if (edadFinDeAnio < 18) {
      return true;
    } else if (edadFinDeAnio === 18) {
      const mesNacimiento = fechaNacimiento.getMonth();
      const diaNacimiento = fechaNacimiento.getDate();
      const mesActual = finDeAnio.getMonth();
      const diaActual = finDeAnio.getDate();

      if (mesNacimiento > mesActual || (mesNacimiento === mesActual && diaNacimiento > diaActual)) {
        return true;
      }
    }

    return false;
  }

  public getfilteredFile(ids: Array<number>): any {
    const isDisabled = this.getControl('isDisabled').value;
    ids = isDisabled ? ids : ids.filter(id => id !== 4);
    const specificFiles = ids.map(index => REQUIRED_FILES.filter((_, i) => i === index))
      .reduce((acc, val) => acc.concat(val), []);
    return { files: specificFiles };
  }

  public newParams(): void {
    const {
      isOver24YearsOld,
      isMinorOrTurned18before31dec,
      isDisabled,
      relationshipId,
      gender,
      genericSecondDate,
      genericFirstDate
    } = this.getControlsData();

    if (relationshipId === 2) {
      this.form.get('gender').setValue('F');
      this.form.get('isDisabled').setValue(false);
      setTimeout(() => {
        this.isGenderDisabled = true;
      }, 500);
    } else {

      this.isGenderDisabled = false;
    }
    if ((relationshipId === 3 || relationshipId === 4) && isOver24YearsOld) {
      this.handleGenericModal(MORE_THAN_24_MODAL_PROPS, false);
    }
    if ((relationshipId === 5) && !isMinorOrTurned18before31dec) {
      this.handleGenericModal(MORE_THAN_18_MODAL_PROPS, false);
    }

    if (relationshipId === 1 && gender === 'M' && isDisabled === false) {
      this.handleGenericModal(MALE_SPOUSE_MODAL_PROPS, false);
    }

    if ((relationshipId === 1 && gender === 'F' && this.isOlderThan30Days(genericFirstDate))) {
      this.handleGenericModal(OLD_MARRIAGE_CERTIFICATE_MODAL_PROPS, false);
    }

    if (relationshipId === 1 && gender === 'M' && (this.isOlderThan30Days(genericSecondDate) || this.isOlderThan30Days(genericFirstDate))) {
      this.handleGenericModal(OLD_MARRIAGE_OR_DISABILITY_CERT_MODAL_PROPS, false);
    }

    if (relationshipId === 2 && (this.isOlderThan30Days(genericSecondDate) || this.isOlderThan30Days(genericFirstDate))) {
      this.handleGenericModal(OLD_WIDOW_CERT_MODAL_PROPS, false);
    }

    if ((relationshipId === 3 || relationshipId === 4 || relationshipId === 5) && (this.isOlderThan30Days(genericFirstDate))) {
      this.handleGenericModal(OLD_BIRTH_CERTIFICATE_MODAL_PROPS, false);
    }

    if ((relationshipId === 3 || relationshipId === 4 || relationshipId === 5)
      && (this.isOlderThan30Days(genericSecondDate)) && genericSecondDate) {
      this.handleGenericModal(OLD_DISABILITY_CERTIFICATE_MODAL_PROPS, false);
    }

    this.requiredFiles = this.filteredFiles();
  }

  setDisclaimerBox(relationshipId, gender, isDisabled, isUnderAge): void {

    this.disclaimerBox = '';
    this.genericFirstDateLabel = '';
    this.genericSecondDateLabel = '';
    if (relationshipId === 1 && gender === 'F') {
      this.disclaimerBox = this.pageContent.personalData.form.disclaimerBoxWife;
      this.genericFirstDateLabel = this.pageContent.personalData.form.mariageCertificateDate;
      this.genericSecondDateLabel = isDisabled ? this.pageContent.personalData.form.disabilityCertificateDate : '';
    }
    if (relationshipId === 1 && gender === 'M') {
      this.disclaimerBox = this.pageContent.personalData.form.disclaimerBoxHusband;
      this.genericFirstDateLabel = this.pageContent.personalData.form.mariageCertificateDate;
      this.genericSecondDateLabel = this.pageContent.personalData.form.disabilityCertificateDate;
    }
    if (relationshipId === 2) {
      this.disclaimerBox = this.pageContent.personalData.form.disclaimerBoxWidow;
      this.genericFirstDateLabel = this.pageContent.personalData.form.motherMariageCertificateDate;
      this.genericSecondDateLabel = this.pageContent.personalData.form.motherHusbandDeathCertificateDate;
    }

    if (((relationshipId === 3 || relationshipId === 4) && isUnderAge) || (relationshipId === 5)) {
      this.disclaimerBox = relationshipId === 4 ? this.pageContent.personalData.form.disclaimerBoxGrandSon :
        this.pageContent.personalData.form.disclaimerBoxSon;
      this.disclaimerBox = relationshipId === 5 ? this.pageContent.personalData.form.disclaimerBoxCareChild : this.disclaimerBox;
      this.genericFirstDateLabel = this.pageContent.personalData.form.birthCertificateDate;
      this.genericSecondDateLabel = isDisabled ? this.pageContent.personalData.form.disabilityCertificateDate : '';
    }

    if ((relationshipId === 3 || relationshipId === 4) && !isUnderAge) {
      this.disclaimerBox = relationshipId === 4 ? this.pageContent.personalData.form.disclaimerBoxAdultGrandSon :
        this.pageContent.personalData.form.disclaimerBoxAdultSon;
      this.genericFirstDateLabel = this.pageContent.personalData.form.birthCertificateDate;
      this.genericSecondDateLabel = isDisabled ? this.pageContent.personalData.form.disabilityCertificateDate : '';
    }

    if (this.form) {
      if (!this.genericSecondDateLabel) {
        this.form.get('genericSecondDate').clearValidators();
        this.form.get('genericSecondDate').updateValueAndValidity();
      } else {
        this.form.get('genericSecondDate').setValidators([Validators.required]);
      }
    }
  }

  private async handleGenericModal(
    modalProps: {
      title: string,
      description: string,
      firstBtnText: string,
      iconName: string;
    },
    out: boolean = true
  ): Promise<void> {
    if (out) {
      this.navService.goTo(affiliateAssessmentPapework);
    }
    this.modalCtrl.dismiss().then(async () => {
      const { title, firstBtnText, iconName, description } = modalProps;
      const primaryCallback = () => { };

      const data = { title, firstBtnText, description, iconName, primaryCallback, largeButton: true };
      await this.modalService.openModal(ModalGenericComponent, { data });
    });
  }

  public getControlsData(): any {
    const birthdate = this.getControl('birthdate').value;
    const isOver24YearsOld = this.isOver24YearsOld(birthdate);
    const isMinorOrTurned18before31dec = this.isMinorOrTurned18before31dec(birthdate);
    const isDisabled = this.form.get('isDisabled').value;
    const relationshipId = +this.getControl('relationshipId').value;
    const gender = this.form.get('gender').value;
    const genericFirstDate = this.getControl('genericFirstDate').value;
    const genericSecondDate = this.getControl('genericSecondDate').value;
    this.setDisclaimerBox(relationshipId, gender, isDisabled, isMinorOrTurned18before31dec);
    this.SelectedRelationship = relationshipId;
    return {
      birthdate,
      isOver24YearsOld,
      isMinorOrTurned18before31dec,
      isDisabled,
      relationshipId,
      gender,
      genericFirstDate,
      genericSecondDate
    };
  }

  public isOlderThan30Days(date: string): boolean {
    const today = new Date();
    const birthDate = new Date(date);
    const diff = today.getTime() - birthDate.getTime();
    return diff > 30 * 24 * 60 * 60 * 1000;
  }

  public isOver24YearsOld(dateOfBirth: string): boolean {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1 > 24;
    }
    return age > 24;
  }

  public getControl(control: string): AbstractControl {
    return this.form.controls[control];
  }



  private createForm(): void {
    const group = {
      operation: [this.getFamilyBurdenAttribute('operation'), Validators.required],
      name: [this.getFamilyBurdenAttribute('name'), [Validators.required, Validators.pattern(ALPHABETIC_PATTERN)]],
      lastName: [this.getFamilyBurdenAttribute('lastName'), [Validators.required, Validators.pattern(ALPHABETIC_PATTERN)]],
      secondSurname: [this.getFamilyBurdenAttribute('secondSurname'), [Validators.required, Validators.pattern(ALPHABETIC_PATTERN)]],
      rut: [this.getFamilyBurdenAttribute('rut'), [Validators.required, ValidateRut]],
      birthdate: [this.getFamilyBurdenAttribute('birthdate'), Validators.required],
      begindate: [this.getFamilyBurdenAttribute('begindate'), Validators.required],
      gender: [this.getFamilyBurdenAttribute('gender'), Validators.required],
      relationshipId: [this.getFamilyBurdenAttribute('relationshipId'), Validators.required],
      isDisabled: [this.getFamilyBurdenAttribute('isDisabled'), Validators.required],
      genericFirstDate: [this.getFamilyBurdenAttribute('genericFirstDate'), Validators.required],
      genericSecondDate: [this.getFamilyBurdenAttribute('genericSecondDate'), Validators.required],
      attachedFiles: this.formBuilder.array(this.getchargueAttachmentFiles(), Validators.required)
    };
    this.form = this.formBuilder.group(group, null);
  }

  private getchargueAttachmentFiles(): [] {
    if (!this.form) { return []; }
    return this.form['attachedFiles'];
  }

  public onFileChange(data: any): void {

    const formArray = this.attachedFiles;
    this.clearAttachmentFiles();
    data.fileList.forEach((file: File) => formArray.push(new FormControl(file)));
    data.keyList.forEach((key: string) => this.keyFiles.push(key));
    this.actionFiles = data;
    this.ifWorkContractOrSiiCertificate(data);
  }

  ifWorkContractOrSiiCertificate(data): void {
    if (this.SelectedRelationship === 3 || this.SelectedRelationship === 4) {
      if (data.keyList.includes('workContract')) {
        const index = this.requiredFiles.files.findIndex(file => file.fileIndex === 'siiCertificate');

        if (index !== -1) {
          this.requiredFiles.files[index].required = false;
        }
      } else if (data.keyList.includes('siiCertificate')) {
        const index = this.requiredFiles.files.findIndex(file => file.fileIndex === 'workContract');

        if (index !== -1) {
          this.requiredFiles.files[index].required = false;
        }
      } else {
        const index = this.requiredFiles.files.findIndex(file => file.fileIndex === 'siiCertificate');
        const index2 = this.requiredFiles.files.findIndex(file => file.fileIndex === 'workContract');
        if (index !== -1 && index2 !== -1) {
          this.requiredFiles.files[index].required = true;
          this.requiredFiles.files[index2].required = true;
        }
      }
    }
  }

  private clearAttachmentFiles(): void {
    this.attachedFiles.clear();
    this.keyFiles = [];
  }

  private getFamilyBurdenAttribute(attribute: string): string {
    if (!this.familyBurden) { return ''; }
    let data = this.familyBurden[attribute];
    if (attribute === 'birthdate' || attribute === 'begindate' || attribute === 'genericFirstDate' || attribute === 'genericSecondDate') {
      data = moment(this.familyBurden[attribute]).utc().toDate();
    }
    return data;
  }

  public isChecked(controlName: string, code: string): boolean {
    const control = this.getControl(controlName);
    return control && control.value === code;
  }

  public addFamilyBurden(): void {
    if (this.form.invalid) { return; }

    const { name, lastName, secondSurname } = this.form.value;
    const fullName = this.util.getFullName(name, lastName, secondSurname);
    const relationship = this.relationship.find(item => item.id === this.form.get('relationshipId').value).description;
    const relationshipiD = this.form.get('relationshipId').value;
    const FamilyBurden: any = {
      rut: this.form.get('rut').value,
      requestType: this.form.get('operation').value,
      name: this.form.get('name').value,
      lastName: this.form.get('lastName').value,
      secondSurname: this.form.get('secondSurname').value,
      birthdate: this.util.getFormattedDate(this.form.get('birthdate').value),
      formatedBirthDate: this.util.getFormattedDate(this.form.get('birthdate').value),
      gender: this.form.get('gender').value,
      relationshipId: this.form.get('relationshipId').value,
      isDisabled: this.form.get('isDisabled').value,
      benefitStartCessationDate: this.util.getFormattedDate(this.form.get('begindate').value),
      birthCertificateDate: this.util.getFormattedDate(this.form.get('genericFirstDate').value),
      disabilityResolutionDate: this.form.get('isDisabled').value === true ?
        this.util.getFormattedDate(this.form.get('genericSecondDate').value) : null,
      maritalCertificateDate: relationshipiD <= 2 ? this.util.getFormattedDate(this.form.get('genericFirstDate').value) : null,
      spouseDeathCertificateDate: relationshipiD === 2 ? this.util.getFormattedDate(this.form.get('genericSecondDate').value) : null,
      relationship,
      fullName,
      style: 'bold',
      fileActions: this.actionFiles,
      requestTypeId: this.translateOperation(this.form.get('operation').value),
    };

    this.closeModal(FamilyBurden);
  }


  private translateOperation(operation: string): string {
    if (operation === 'add') { return 'AFFILIATE.FAMILY_ASIGNATION.OPERATIONS.ADD'; }
    if (operation === 'modify') { return 'AFFILIATE.FAMILY_ASIGNATION.OPERATIONS.MODIFY'; }
    if (operation === 'delete') { return 'AFFILIATE.FAMILY_ASIGNATION.OPERATIONS.DELETE'; }
  }
}
