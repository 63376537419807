import { IChartistData, IPieChartOptions } from 'chartist';

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ChartType } from '@components/chartist/chartist.component';
import { FontService } from '@providers/font/font.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() public isLinear = false;
  @Input() public canGoBackwards = false;
  @Input() public steps: Array<string> = [];
  @Input() public currentIndex = 0;
  @Output() public changedStep: EventEmitter<number> = new EventEmitter();
  public chartType: ChartType = 'Pie';
  public chartOptions: IPieChartOptions = {
    donut: true,
    showLabel: false,
    chartPadding: 0,
    height: '58px',
    width: '58px',
    donutWidth: '22px',
  };
  @Input() public showLabelStep = false;
  public screenWidth: number;

  constructor(
    public font: FontService,
  ) {
    this.screenWidth = window.innerWidth;
  }

  public get chartData(): IChartistData {
    return {
      series: [
        { value: this.currentStepPercentage, className: 'stepper complete' },
        { value: 100 - this.currentStepPercentage, className: 'stepper uncomplete' },
      ]
    };
  }

  public get currentStepPercentage(): number { return this.stepPercentage * this.currentIndex; }
  public get stepPercentage(): number { return Math.round(100 / (this.steps.length - 1)); }
  public get firstStep(): number { return 0; }
  public get lastStep(): number { return this.steps.length - 1; }
  public get unionLinesMargin(): number { return -100 / (this.steps.length * 10); }
  public get currentLabel(): string { return this.steps[this.currentIndex]; }
  public isFirst(index: number): boolean { return index === this.firstStep; }
  public isLast(index: number): boolean { return index === this.lastStep; }
  public isCurrent(index: number): boolean { return index === this.currentIndex; }
  public isNextToCurrent(index: number): boolean { return index === this.currentIndex + 1; }
  public isBeforeCurrent(index: number): boolean { return index < this.currentIndex; }

  public nextStep(): void {
    if (this.currentIndex === this.lastStep) { return; }
    this.changeStep(this.currentIndex + 1);
  }

  public previousStep(): void {
    if (this.currentIndex === this.firstStep) { return; }
    this.changeStep(this.currentIndex - 1);
  }

  public canGoToStep(index: number): boolean {
    return !this.isLinear || (this.canGoBackwards && this.isBeforeCurrent(index));
  }

  public onStepClicked(index: number): void {
    if (!this.canGoToStep(index)) { return; }
    this.changeStep(index);
  }

  public changeStep(index: number): void {
    if (index < this.firstStep || index > this.lastStep) { return; }
    this.currentIndex = index;
    this.changedStep.emit(index);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(_event) {
    this.screenWidth = window.innerWidth;
  }

  public isVisibleOnMobile(index: number): boolean {
    return index === this.currentIndex ||
      index === this.currentIndex + 1 ||
      (this.currentIndex === this.lastStep && index === this.currentIndex - 1);
  }

  public isDesktop(): boolean {
    return this.screenWidth > 1085;
  }

}
