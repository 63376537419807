import { Component } from '@angular/core';
import { ModalComponent } from '@components/modal/modal.component';
import { Card } from '@interfaces/info-card.interface';
import { ModalController, NavParams } from '@ionic/angular';
import { FontService } from '@providers/font/font.service';
import { NavigationService } from '@providers/navigation/navigation.service';

@Component({
  selector: 'app-modal-family-allowance-affiliate',
  templateUrl: './modal-family-allowance-affiliate.component.html',
  styleUrls: ['./modal-family-allowance-affiliate.component.scss'],
})
export class ModalFamilyAllowanceAffilaiteComponent extends ModalComponent {
  public information: Card<any>;
  modalInformation: string;

  constructor(
    public font: FontService,
    protected params: NavParams,
    protected modalCtrl: ModalController,
    private navService: NavigationService

  ) {
    super(params, modalCtrl);
    this.information = this.params.get('information');
    this.modalInformation = 'AFFILIATE.ASSESSMENT.PAPERWORK_CENTER.MORE_INFO_CARD.FAMILY_ASIGNATION.MODAL_INFORMATION';
  }

  goTo(): void {
    this.navService.goTo(this.information.requestUrl);
    this.closeModal();
  }
}
