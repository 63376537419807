<div class="modal modal-container" *ngIf="loadData">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>

  <form class="form-container" [formGroup]="form" (submit)="addFamilyBurden()">
    <h1 class="title primary" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h1>
    <h2 class="title secondary" [ngClass]="font?.size">{{ pageContent?.personalData.title | translate }}</h2>

    <mat-radio-group class="radio" formControlName="operation">
      <div>
        <mat-radio-button class="mr-2" value="add" [class.selected]="isChecked('chargue', 'add')"
          [ngClass]="font?.size">
          {{ pageContent?.personalData.form.chargue.add | translate }}</mat-radio-button>
        <mat-radio-button class="mr-2" value="modify" [class.selected]="isChecked('chargue', 'modify')"
          [ngClass]="font?.size">
          {{ pageContent?.personalData.form.chargue.modify | translate }}</mat-radio-button>
        <mat-radio-button class="mr-2" value="delete" [class.selected]="isChecked('chargue', 'delete')"
          [ngClass]="font?.size">
          {{ pageContent?.personalData.form.chargue.delete | translate }}</mat-radio-button>
      </div>
    </mat-radio-group>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.name | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="name" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('name').hasError('required')"
          [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('name').hasError('pattern')"
          [innerHTML]="pageContent?.errors.patternLetters | translate"></mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.lastName | translate"
          style="font-size: smaller;"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="lastName" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('lastName').hasError('required')"
          [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('lastName').hasError('pattern')"
          [innerHTML]="pageContent?.errors.patternLetters | translate"></mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.secondSurname | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="secondSurname">
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('secondSurname').hasError('required')"
          [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('secondSurname').hasError('pattern')"
          [innerHTML]="pageContent?.errors.patternLetters | translate"></mat-error>
      </mat-form-field>
    </div>

    <div class="fields-group">
      <mat-form-field [ngClass]="font?.size">
        <mat-label [innerHTML]="pageContent?.personalData.form.rut | translate"></mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="rut" placeholder="" [formatRut]="rut"
          rutInput required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="rut.hasError('required')"
          [innerHTML]="pageContent?.errors.required | translate"></mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="!rut.hasError('required') && rut.hasError('validRut')"
          [innerHTML]="pageContent?.errors.invalidRut | translate"></mat-error>
      </mat-form-field>

      <mat-form-field class="calendar-input" [ngClass]="font?.size">
        <mat-label>{{ pageContent?.personalData.form.birthdate | translate }}</mat-label>
        <input readonly [ngClass]="font?.size" formControlName="birthdate" (dateChange)="newParams()" matInput
          [matDatepicker]="birthdatePicker" [max]="maxDate" required>
        <mat-datepicker-toggle [for]="birthdatePicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #birthdatePicker></mat-datepicker>
      </mat-form-field>

      <mat-radio-group class="radio" formControlName="gender" (change)="newParams()" [disabled]="isGenderDisabled">
        <h4 class="subtitle-secondary label-width" [ngClass]="font?.size">{{ pageContent?.personalData.form.gender.label
          | translate
          }}</h4>
        <div>
          <mat-radio-button value="F" [class.selected]="isChecked('gender', 'F')" [ngClass]="font?.size">
            {{ pageContent?.personalData.form.gender.female | translate }}</mat-radio-button>
          <mat-radio-button value="M" [class.selected]="isChecked('gender', 'M')" [ngClass]="font?.size">
            {{ pageContent?.personalData.form.gender.male | translate }}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="fields-group">
      <mat-form-field class="calendar-input" [ngClass]="font?.size">
        <mat-label>{{ pageContent?.personalData.form.beginDate | translate }}</mat-label>
        <input readonly [ngClass]="font?.size" formControlName="begindate" (dateChange)="newParams()" matInput
          [matDatepicker]="beginDatepicker" [min]="this.getControl('birthdate').value" [max]="maxDate" required
          [disabled]="!this.getControl('birthdate').value">
        <mat-datepicker-toggle [for]="beginDatepicker"
          [disabled]="!this.getControl('birthdate').value"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #beginDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size">
        <mat-label>{{ pageContent?.personalData.form.relationship | translate }}</mat-label>
        <mat-select formControlName="relationshipId" [ngClass]="font?.size" (selectionChange)="newParams()"
          [disabled]="!this.getControl('birthdate').value" required>
          <mat-option [ngClass]="font?.size" *ngFor="let relationship of this.relationship" [value]="relationship.id">
            {{ relationship.description | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size" *ngIf="getControl('relationshipId').hasError('required')">{{
          pageContent?.errors.required | translate }}</mat-error>
      </mat-form-field>

      <mat-radio-group class="radio" formControlName="isDisabled" (change)="newParams()" [disabled]="isGenderDisabled">
        <h4 class="subtitle-secondary label-width" [ngClass]="font?.size">{{ pageContent?.personalData.form.isDisabled |
          translate
          }}</h4>
        <div>
          <mat-radio-button [value]="true" [class.selected]="getControl('isDisabled').value" [ngClass]="font?.size"> {{
            pageContent?.form.positiveAnswer | translate }} </mat-radio-button>
          <mat-radio-button [value]="false" [class.selected]="getControl('isDisabled').value === false"
            [ngClass]="font?.size"> {{ pageContent?.form.negativeAnswer | translate }} </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <h1 class="title primary" [ngClass]="font?.size" *ngIf="genericFirstDateLabel">{{
      pageContent?.secondTitle | translate }} </h1>

    <div class="fields-group extra-data">
      <mat-form-field class="calendar-input more-width" [ngClass]="font?.size" *ngIf="genericFirstDateLabel">
        <mat-label>{{ genericFirstDateLabel | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="genericFirstDate" (dateChange)="newParams()" matInput
          [matDatepicker]="genericFirstDatepicker" [min]="this.getControl('birthdate').value" [max]="maxDate" required
          readonly>
        <mat-datepicker-toggle [for]="genericFirstDatepicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #genericFirstDatepicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="calendar-input more-width" [ngClass]="font?.size" *ngIf="genericSecondDateLabel">
        <mat-label>{{ genericSecondDateLabel | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="genericSecondDate" (dateChange)="newParams()" matInput
          [matDatepicker]="genericSecondDatePicker" [min]="this.getControl('birthdate').value" [max]="maxDate" required
          readonly>
        <mat-datepicker-toggle [for]="genericSecondDatePicker"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #genericSecondDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="disclaimer-box" *ngIf="disclaimerBox">
      <p class="disclaimer-title" [ngClass]="font?.size"><b>{{ disclaimerBox+'.TITLE' | translate }}</b></p>
      <p class="disclaimer-subtitle" [ngClass]="font?.size"><b>{{ disclaimerBox+'.SUBTITLE' | translate }}</b></p>
      <p class="disclaimer-text" [innerHTML]="disclaimerBox+'.TEXT' | translate"></p>
    </div>

    <app-attach-files *ngIf="this.getControl('relationshipId').value" [attachFiles]="requiredFiles"
      [preloadedFiles]="filesLoaded" (fileEmitter)="onFileChange($event)" class="h-scroll">
    </app-attach-files>

    <div class="button-container">
      <ion-button class="button primary form-button" [ngClass]="font?.size" type="submit"
        [disabled]="form.invalid || !isAtachedfilesValid">
        {{ buttonText | translate }}
      </ion-button>
    </div>
  </form>
</div>