<div class="page-container">
  <div class="voucher-box">
    <div class="i-check-confirm-step icon-check"></div>
    <h1 class="title" [ngClass]="font?.size" [innerHTML]="voucher?.title | translate"></h1>
    <h1 class="subtitle" [ngClass]="font?.size" *ngIf="voucher?.subtitle" [innerHTML]="voucher?.subtitle | translate "></h1>
    <p class="subtitle" [ngClass]="font?.size" *ngIf="showDescription" [innerHTML]="voucher?.description | translate"></p>

    <div class="i-stamp-plan-vital icon-stamp"></div>
    <div class="button-container">
      <ion-button *ngIf="!!this.file" class="btn primary large" [ngClass]="font?.size" [innerHTML]="voucher?.download | translate" (click)="downloadPdf()"></ion-button>
      <ion-button class="button primary" *ngIf="customRouteButton" [ngClass]="font?.size" (click)="goTo(customRouteButton)" [innerHTML]="voucher?.customRouteButton | translate"></ion-button>
      <ion-button class="button secondary" [ngClass]="font?.size" (click)="goTo(homeRoute)" [innerHTML]="voucher?.homeButton | translate"></ion-button>
    </div>
  </div>
</div>
