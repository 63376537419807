<div class="content-container general-container">
  <ng-container *ngIf="!showForm">
    <div class="button-container" *ngIf="showPrimaryButton">
      <ion-button class="button primary large-button" [ngClass]="font?.size" (click)="getRequest()">
        {{ pageContent?.buttons.goForm | translate }}
      </ion-button>
    </div>
    <div class="secondary-container" [ngClass]="font?.size">
      {{ pageContent?.disclaimerSection.question | translate }}
      <div class="subtitle-secondary" [ngClass]="font?.size">
        {{ pageContent?.disclaimerSection.disclaimer | translate }}
      </div>
    </div>
    <div class="button-container">
      <ion-button class="button secondary large-button" [ngClass]="font?.size" (click)="setShowForm(true)">
        {{ pageContent?.buttons?.continue | translate }}
        <div class="icon-result secondary-next"></div>
      </ion-button>
    </div>
  </ng-container>
  <ng-container *ngIf="showForm">
    <div class="title" [ngClass]="font?.size">{{ pageContent?.title | translate }}</div>
    <p class="subtitle-secondary" [ngClass]="font?.size" [innerHTML]="pageContent?.description | translate"></p>

    <div class="form-container">
      <p class="description" [ngClass]="font?.size">{{ pageContent?.subDescription | translate }}</p>

      <form class="form" [formGroup]="form" (ngSubmit)="getRequest()" autocomplete="off">
        <div class="options-container">
          <mat-radio-group name="options" formControlName="typeSearchOption">
            <p class="option-label" [ngClass]="font?.size">{{ pageContent?.validateUser.form.search | translate }}</p>
            <mat-radio-button class="option" [ngClass]="font?.size" *ngFor="let option of typeSearchOptions"
              name="options" [value]="option.id">
              {{ option.name }}
            </mat-radio-button>
          </mat-radio-group>

          <div class="options-container search-by">
            <mat-form-field [ngClass]="font?.size">
              <mat-label class="option-label" [ngClass]="font?.size">{{ pageContent?.validateUser.form.rut | translate
                }}</mat-label>
              <input matInput type="text" formControlName="rut" placeholder="12.345.678-9" [formatRut]="rut" rutInput
                required>
              <span matSuffix class="i-validator"></span>
              <mat-error *ngIf="rut.hasError('required')" [ngClass]="font?.size">
                {{ pageContent?.errors.required | translate }}
              </mat-error>
              <mat-error *ngIf="!rut.hasError('required') && rut.hasError('validRut')" [ngClass]="font?.size">
                {{ pageContent?.errors.invalidRut | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="number-clean" [hidden]="!phoneOptionSelected" [ngClass]="font?.size">
              <mat-label class="option-label" [ngClass]="font?.size">{{ pageContent?.validateUser.form.options.phone |
                translate }}</mat-label>
              <input matInput type="number" [min]="phoneNumberMin" [max]="phoneNumberMax" formControlName="phone"
                placeholder="987654321" [max]="999999999" [maxLength]="phoneNumberLength"
                [minLength]="phoneNumberLength" required digitsOnly>
              <span matSuffix class="i-validator"></span>
              <mat-error *ngIf="phone.hasError('required')" [ngClass]="font?.size">
                {{ pageContent?.errors.required | translate }}
              </mat-error>
              <mat-error *ngIf="phone.hasError('min') || phone.hasError('max')" [ngClass]="font?.size">
                {{ pageContent?.errors.invalidCellPhone | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field [hidden]="!emailOptionSelected" [ngClass]="font?.size">
              <mat-label class="option-label" [ngClass]="font?.size">{{ pageContent?.validateUser.form.options.email |
                translate }}</mat-label>
              <input matInput type="email" formControlName="email" required>
              <span matSuffix class="i-validator"></span>
              <mat-error *ngIf="email.hasError('required')" [ngClass]="font?.size">
                {{ pageContent?.errors.required | translate }}
              </mat-error>
              <mat-error *ngIf="email.hasError('email') || email.hasError('pattern')" [ngClass]="font?.size">
                {{ pageContent?.errors.invalidEmail | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="button-container">
          <ion-button class="button secondary" [ngClass]="font?.size" (click)="setShowForm(false)">
            <div class="icon-result back"></div>
            {{ pageContent?.buttons?.back | translate }}
          </ion-button>
          <ion-button class="button primary" [ngClass]="font?.size" type="submit" [disabled]="form.invalid">
            {{ pageContent?.buttons.submit | translate }}
            <div class="icon-result next"></div>
          </ion-button>
        </div>

      </form>
    </div>
  </ng-container>
</div>