<div class="stepper-header">
  <ng-container *ngFor="let label of steps; let index = index">
    <div *ngIf="isVisibleOnMobile(index) || isDesktop()" class="step" [ngClass]="{
        'final': isLast(index),
        'current': isCurrent(index),
        'done': isBeforeCurrent(index),
        'isMobile': !isDesktop() && index === currentIndex +1
      }">
      <div class="icon-line-container">
        <div class="icon-container">
          <div class="icon" (click)="onStepClicked(index)" [class.clickable]="canGoToStep(index)"></div>
        </div>
        <div *ngIf="!isLast(index)" class="steps-union-line" [hidden]="isLast(index)"
          [class.done]="isBeforeCurrent(index)" [class.pending]="isNextToCurrent(index)"></div>
      </div>
      <div class="description label" [ngClass]="font?.size">
        <p *ngIf="showLabelStep === true && !isLast(index)">PASO {{index + 1}}</p>
        <p>{{ label | translate }}</p>
      </div>
    </div>
  </ng-container>
</div>