<form class="form-container" [formGroup]="form" autocomplete="off" *ngIf="form">
  <h3 class="title-secondary spacing" [ngClass]="font?.size">{{ pageContent?.title | translate }}</h3>
  <p class="subtitle-secondary" [ngClass]="font?.size">{{ pageContent?.description | translate }}</p>
  <div class="form-section">
    <div class="fields-group inline">
      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('names')">
        <mat-label>{{ pageContent?.fields.names | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="names" [maxLength]="namesMaxLength"
          required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('names')">
          {{ errors.errors.required | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('names')">{{ errors.errors.patternLetters |
          translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('name')">
        <mat-label>{{ pageContent?.fields.names | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="name" [maxLength]="nameMaxLength" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('name')">
          {{ errors.errors.required | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('name')">{{ errors.errors.patternLetters |
          translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('lastName')">
        <mat-label>{{ pageContent?.fields.lastName | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="lastName" [maxLength]="namesMaxLength"
          [required]="isRequired('lastName')">
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('lastName')">
          {{ errors.errors.required | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('lastName')">
          {{ errors.errors.patternLetters | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('secondSurname')">
        <mat-label>{{ pageContent?.fields.secondSurname | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="secondSurname" [maxLength]="namesMaxLength"
          [required]="isRequired('secondSurname')">
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('secondSurname')">
          {{ errors.errors.required | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('secondSurname')">
          {{ errors.errors.patternLetters | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('rut')">
        <mat-label>{{ pageContent?.fields.rut | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="rut" placeholder="12.345.678-9"
          [maxLength]="rutMaxLength" [formatRut]="rut" rutInput required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('rut')">
          {{ errors.errors.required | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="!requiredError('rut') && rut.hasError('validRut')">
          {{ errors.errors.invalidRut | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size"
          *ngIf="!requiredError('rut') && !rut.hasError('validRut') && rut?.hasError('mustMatch')">
          {{ errors.errors.mustMatchRut | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('email')">
        <mat-label>{{ pageContent?.fields.email | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="email" [maxLength]="namesMaxLength"
          required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('email')">{{ errors.errors.required | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="patternError('email')">{{ errors.errors.invalidEmail | translate
          }}</mat-error>
      </mat-form-field>

      <mat-form-field class="number-clean field" [ngClass]="font?.size" *ngIf="getControl('cellphone')">
        <mat-label>{{ pageContent?.fields.cellphone | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="number" [min]="phoneNumberMin" [max]="phoneNumberMax"
          placeholder="223456789" formControlName="cellphone" [minlength]="phoneNumberLength"
          [maxlength]="phoneNumberLength" required digitsOnly>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size" *ngIf="requiredError('cellphone')">
          {{ errors.errors.required | translate }}
        </mat-error>
        <mat-error [ngClass]="font?.size" *ngIf="cellphoneError('cellphone')">
          {{ errors.errors.invalidPhone | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('maritalStatus')">
        <mat-label>{{ pageContent?.fields.maritalStatus | translate }}</mat-label>
        <mat-select formControlName="maritalStatus" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let maritalStatus of maritalStatusSelector"
            [value]="maritalStatus[0]">
            {{ maritalStatus[1] | translate }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="calendar-input" [ngClass]="font?.size" *ngIf="getControl('deceasedDate')">
        <mat-label>{{ pageContent?.fields.deceasedDate | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="deceasedDateInput" (dateChange)="dateSelected('deceasedDate')"
          matInput [matDatepicker]="datepickerDeceased" [min]="minDeceasedDate" [max]="maxDeceasedDate">
        <mat-datepicker-toggle [for]="datepickerDeceased"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #datepickerDeceased></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="calendar-input" [ngClass]="font?.size" *ngIf="getControl('birthdate')">
        <mat-label>{{ pageContent?.fields.birthdate | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="birthdateInput" (dateChange)="dateSelected('birthdate')" matInput
          [matDatepicker]="datepickerBirthDate" [min]="minBirthdate" [max]="maxBirthdate">
        <mat-datepicker-toggle [for]="datepickerBirthDate"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #datepickerBirthDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="calendar-input" [ngClass]="font?.size" *ngIf="getControl('selectionDate')">
        <mat-label>{{ pageContent?.fields.selectionDate | translate }}</mat-label>
        <input [ngClass]="font?.size" formControlName="selectionDateInput" (dateChange)="dateSelected('selectionDate')"
          matInput [matDatepicker]="datepickerSelectionDate" [min]="minSelectionDate" [max]="maxSelectionDate">
        <mat-datepicker-toggle [for]="datepickerSelectionDate"></mat-datepicker-toggle>
        <mat-datepicker class="icon" #datepickerSelectionDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('profession')">
        <mat-label>{{ pageContent?.fields.profession | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="profession" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('nationality')">
        <mat-label>{{ pageContent?.fields.nationality | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="nationality" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-radio-group class="option-container" formControlName="gender" *ngIf="getControl('gender')">
        <p class="option-label" [ngClass]="font?.size">{{ pageContent?.fields.gender.label | translate }}</p>
        <mat-radio-button value="F" class="option" [class.selected]="isChecked('gender', 'F')" [ngClass]="font?.size">
          {{ pageContent?.fields.gender.female | translate }}
        </mat-radio-button>
        <mat-radio-button value="M" class="option" [class.selected]="isChecked('gender', 'M')" [ngClass]="font?.size">
          {{ pageContent?.fields.gender.male | translate }}
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('relationship')">
        <mat-label>{{ pageContent?.fields.relationship | translate }}</mat-label>
        <mat-select formControlName="relationship" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let relationship of relationships" [value]="relationship.id">
            {{ relationship.description | translate | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('region')">
        <mat-label>{{ pageContent?.fields.region | translate }}</mat-label>
        <mat-select formControlName="region" required>
          <mat-option *ngFor="let region of regions" [value]="region.code">{{ region.description | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('commune')">
        <mat-label>{{ pageContent?.fields.commune | translate }}</mat-label>
        <mat-select formControlName="commune" required>
          <mat-option *ngFor="let commune of communes" [value]="commune.code">{{ commune.description | titlecase }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field [ngClass]="font?.size" *ngIf="getControl('address')">
        <mat-label>{{ pageContent?.fields.address | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="text" formControlName="address" required>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="field" [ngClass]="font?.size" *ngIf="getControl('commissionAmount')">
        <mat-label>{{ pageContent?.fields.commissionAmount | translate }}</mat-label>
        <input [ngClass]="font?.size" matInput type="number" placeholder="UF" formControlName="commissionAmount"
          required digitsOnly>
        <span matSuffix class="i-validator"></span>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="long-field" [ngClass]="font?.size" *ngIf="getControl('healthcare')">
        <mat-label>{{ pageContent?.fields.healthcare| translate }}</mat-label>
        <mat-select formControlName="healthcare" [ngClass]="font?.size">
          <mat-option [ngClass]="font?.size" *ngFor="let healthcare of healthcareInstitutions" [value]="healthcare">
            {{ healthcare }}
          </mat-option>
        </mat-select>
        <mat-error [ngClass]="font?.size">
          {{ errors.errors.required | translate }}
        </mat-error>
      </mat-form-field>

      <div class="check-group big-field" *ngIf="getControl('workSituation')">
        <p class="text-label" [ngClass]="font?.size">{{ pageContent?.fields.workSituation | translate }}</p>
        <mat-radio-group class="options custom-border" formControlName="workSituation">
          <div class="option" [class.selected]="isChecked('workSituation', workOption[0])"
            *ngFor="let workOption of workOptions">
            <div class="left-radio-button">
              <mat-radio-button [value]="workOption[0]"></mat-radio-button>
            </div>
            <div class="right-radio-button text-label" [ngClass]="font?.size">{{ workOption[1].title | translate |
              titlecase }}
            </div>
            <app-info-text-popup class="tooltip-icon" [definitions]="workOption[1].definitions"
              iconName="i-tooltip-blue">
              <div class="i-tooltip-blue"></div>
            </app-info-text-popup>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
</form>