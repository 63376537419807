


export const APV_REDIRECT_TYPE = 'apv';
export const CAV_REDIRECT_TYPE = 'cav';

export const DISABILITY_PENSION_REDIRECT_TYPE = 'disabilityPension';
export const ELDERLY_PENSION_REDIRECT_TYPE = 'elderlyPension';
export const ANTICIPATED_PENSION_REDIRECT_TYPE = 'anticipatedPension';
export const OFFER_REQUEST_REDIRECT_TYPE = 'offerRequest';
export const FOREIGN_WITHDRAWAL_REDIRECT_TYPE = 'foreignWithdrawal';
export const TRANSFER_CAV_REDIRECT_TYPE = 'transferCav';
export const TRANSFER_ACCOUNTS_REDIRECT_TYPE = 'transferAccounts';
export const SURPLUS_CALCULATION_REDIRECT_TYPE = 'surplusCalculation';
export const SURPLUS_PAYMENT_REDIRECT_TYPE = 'surplusPayment';
export const DISABILITY_REEVALUATION_REDIRECT_TYPE = 'disabilityReevaluation';
export const OVERPAYMENT_REFUND_REDIRECT_TYPE = 'overpaymentRefund';
export const CAI_WITHDRAWAL_REDIRECT_TYPE = 'caiWithdrawal';
export const REQUEST_FOR_TRANSFER_FUNDS_FROM_INDIVIDUAL_ACCOUNT_REDIRECT_TYPE = 'requestForTransferFundsFromIndividualAccount';
export const REQUEST_SURPLUS_FUNDS_SIMULATOR_REDIRECT_TYPE = 'fundSimulation';
export const ADDITIONAL_PENSION_APPLICATION_TYPE = 'additionalPensionApplicationFormFileType';
export const DEPOSIT_IPS_TYPE = 'depositIpsFormFileType';
export const LIFE_ANNUITY_APPLICATION_REDIRECT_TYPE = 'lifeAnnuityApplicationFormFileType';
export const LIFE_ANNUITY_DECISION_REDIRECT_TYPE = 'lifeAnnuityDecisionFormFileType';
export const CALCULATION_AND_BOND_RECOGNITION_REDIRECT_TYPE = 'calculationAndBondRecognitionFormFileType';
export const SELECT_MODALITY_BOND_RECOGNITION_REDIRECT_TYPE = 'selectModalityBondRecognitionFormFileType';
export const FAMILY_ALLOWANCE_AFFILLIATES_FORM_FILE_TYPE = 'familyAsignation';
