<div class="" [ngClass]="font?.size">
  <p class="confirm-title"> {{pageContent.title | translate}}</p>
  <div *ngFor="let tables of properties; let i = index">
    <div *ngIf="tables.type !== 'documents'; else documentsTemplate">
      <div (click)="toggleInfo(i)" class="header-container">
        <h1 class="title-toggle">{{tables.key +'.TITLE' | translate}}</h1>
        <div class="{{tables.isOpen ? 'i-arrow-circle-up-red' : 'i-arrow-circle-down-red'}}"></div>
      </div>
      <div class="table-container" *ngIf="tables.isOpen">
        <table *ngIf="tables.isList">
          <tr>
            <th *ngFor="let keys of tables.keys">{{ tables.key +'.KEYS.'+keys| translate}}</th>
          </tr>
          <tr *ngFor="let item of tables.list">
            <td *ngFor="let keyValue of tables.values">{{ getTranslate(item[keyValue],keyValue) | translate }}</td>
          </tr>
        </table>

        <table *ngIf="!tables.isList">
          <tr *ngFor="let keys of tables.keys; index as i">
            <td class="ligth-header">{{ tables.key +'.KEYS.'+keys | translate}}</td>
            <td>{{ getTranslate(tables.list[0][tables.values[i]],tables.values[i]) | translate }}</td>
          </tr>
        </table>
      </div>
    </div>
    <ng-template #documentsTemplate>
      <div (click)="toggleInfo(i)" class="header-container">
        <h1 class="title-toggle">{{tables.key +'.TITLE' | translate}}</h1>
        <div class="{{tables.isOpen ? 'i-arrow-circle-up-red' : 'i-arrow-circle-down-red'}}"></div>
      </div>
      <div class="table-container" *ngIf="tables.isOpen">
        <table>
          <tr>
            <td *ngFor="let document of tables.list">
              <div class="documents-container">
                <div class="i-check-square-red"></div>
                <p>{{document.name}}</p>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </ng-template>
  </div>
</div>