<div class="" [ngClass]="font?.size">
  <p class="confirm-title"> {{pageContent.title | translate}}</p>
  <div *ngFor="let tables of properties">
    <p class="confirm-description"> {{tables.key +'.TITLE' | translate}}</p>
    <hr>
    <div class="table-container">
      <table *ngIf="tables.isList">
        <tr>
          <th *ngFor="let keys of tables.keys">{{ tables.key +'.KEYS.'+keys| translate}}</th>
        </tr>
        <tr *ngFor="let item of tables.list">
          <td *ngFor="let keyValue of tables.values" [class.one-line]="keyValue === 'rut'">{{
            getTranslate(item[keyValue],keyValue) }}</td>
        </tr>
      </table>

      <table *ngIf="!tables.isList">
        <tr *ngFor="let keys of tables.keys; index as i">
          <td class="ligth-header">{{ tables.key +'.KEYS.'+keys | translate}}</td>
          <td>{{ getTranslate(tables.list[0][tables.values[i]],tables.values[i]) }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>