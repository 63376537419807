<div class="modal modal-information-container">
  <ion-button class="button transparent close-modal" [ngClass]="font?.size" (click)="closeModal()">
    <ion-icon class="icon" name="close"></ion-icon>
  </ion-button>
  <div class="information-content">
    <div class="title" [ngClass]="font?.size" [innerHTML]="information?.title | translate"></div>
    <div class="description dynamic-text tertiary" [ngClass]="font?.size"
      [innerHTML]="information?.description | translate"></div>
    <div class="image-container" *ngIf="information?.image?.url">
      <img class="information-image" src="{{ information?.image.url | translate }}" alt="{{ information?.image.alt }}">
    </div>
  </div>
  <div class="centered-container">
    <ion-button class="button primary back" [ngClass]="font?.size" (click)="closeModal()">{{ backButton | translate
      }}</ion-button>
  </div>
</div>