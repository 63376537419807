import { Validators } from '@angular/forms';

import { VALIDATORS } from './forms.constant';

export const rutControlKey = 'rut';
export const healthcareControlKey = 'healthcare';
export const deceasedDateControlKey = 'deceasedDate';
export const birthdateControlKey = 'birthdate';
export const regionKey = 'region';
export const communeKey = 'commune';
export const relationshipControlKey = 'relationship';
export const selectionDateKey = 'selectionDate';

export type PersonBackgroundsDefaultType =
  'names' |
  'name' |
  'lastName' |
  'secondSurname' |
  'rut' |
  'email' |
  'cellphone' |
  'maritalStatus' |
  'gender' |
  'deceasedDate' |
  'birthdate' |
  'profession' |
  'healthcare' |
  'address' |
  'nationality' |
  'region' |
  'commune' |
  'relationship' |
  'selectionDate' |
  'workSituation' |
  'commissionAmount';

export const PERSON_BACKGROUNDS_DEFAULT_CONTROLS = {
  names: VALIDATORS.NAME,
  name: VALIDATORS.NAME,
  lastName: VALIDATORS.NAME,
  secondSurname: VALIDATORS.OPTIONAL_NAME,
  [rutControlKey]: VALIDATORS.RUT,
  email: VALIDATORS.EMAIL,
  cellphone: VALIDATORS.PHONE,
  maritalStatus: Validators.required,
  [relationshipControlKey]: Validators.required,
  gender: Validators.required,
  [deceasedDateControlKey]: Validators.required,
  [birthdateControlKey]: Validators.required,
  profession: Validators.required,
  [healthcareControlKey]: Validators.required,
  address: Validators.required,
  nationality: Validators.required,
  [regionKey]: Validators.required,
  [communeKey]: Validators.required,
  [selectionDateKey]: Validators.required,
  commissionAmount: Validators.required,
  workSituation: Validators.required,
};

const backgroundFieldsKey = `GENERAL_BACKGROUND_FIELDS.FIELDS`;
const errorsBackgroundFieldsKey = `GENERAL_BACKGROUND_FIELDS.ERRORS`;
const maritalStatusKey = `${backgroundFieldsKey}.MARITAL_STATUS.OPTIONS`;

export const GENERAL_BACKGROUND_FIELDS_CONTENT = {
  fields: {
    names: `${backgroundFieldsKey}.NAMES`,
    lastName: `${backgroundFieldsKey}.LAST_NAME`,
    secondSurname: `${backgroundFieldsKey}.SECOND_SURNAME`,
    rut: `${backgroundFieldsKey}.RUT`,
    email: `${backgroundFieldsKey}.EMAIL`,
    cellphone: `${backgroundFieldsKey}.CELLPHONE`,
    maritalStatus: `${backgroundFieldsKey}.MARITAL_STATUS.LABEL`,
    gender: {
      label: `${backgroundFieldsKey}.GENDER.LABEL`,
      female: `${backgroundFieldsKey}.GENDER.FEMALE`,
      male: `${backgroundFieldsKey}.GENDER.MALE`,
    },
    deceasedDate: `${backgroundFieldsKey}.DECEASED_DATE`,
    birthdate: `${backgroundFieldsKey}.BIRTHDATE`,
    profession: `${backgroundFieldsKey}.PROFESION`,
    healthcare: `${backgroundFieldsKey}.HEALTHCARE`,
    address: `${backgroundFieldsKey}.ADDRESS`,
    nationality: `${backgroundFieldsKey}.NATIONALITY`,
    [regionKey]: `${backgroundFieldsKey}.REGION`,
    [communeKey]: `${backgroundFieldsKey}.COMMUNE`,
    [selectionDateKey]: `${backgroundFieldsKey}.SELECTION_DATE`,
    commissionAmount: `${backgroundFieldsKey}.COMMISSION_AMOUNT`,
    workSituation: `${backgroundFieldsKey}.WORK_SITUATION`,
  }
};

export const MARITAL_STATUS_DESCRIPTION = {
  A: `${maritalStatusKey}.A`,
  S: `${maritalStatusKey}.S`,
  C: `${maritalStatusKey}.C`,
  V: `${maritalStatusKey}.V`,
  D: `${maritalStatusKey}.D`,
  P: `${maritalStatusKey}.P`,
};

export const ERROR_INPUT_TEXT = {
  errors: {
    required: `${errorsBackgroundFieldsKey}.REQUIRED`,
    patternLetters: `${errorsBackgroundFieldsKey}.PATTERN_LETTERS`,
    patternNumbers: `${errorsBackgroundFieldsKey}.PATTERN_NUMBERS`,
    patternAddress: `${errorsBackgroundFieldsKey}.PATTERN_ADDRESS`,
    patternAccount: `${errorsBackgroundFieldsKey}.PATTERN_ACCOUNT`,
    patternRutAccount: `${errorsBackgroundFieldsKey}.PATTERN_RUT_ACCOUNT`,
    patternVirtualAccount: `${errorsBackgroundFieldsKey}.PATTERN_VIRTUAL_ACCOUNT`,
    patternMatch: `${errorsBackgroundFieldsKey}.PATTERN_MATCH`,
    invalidRut: `${errorsBackgroundFieldsKey}.INVALID_RUT`,
    mustMatchRut: `${errorsBackgroundFieldsKey}.MUST_MATCH_RUT`,
    invalidPhone: `${errorsBackgroundFieldsKey}.INVALID_PHONE`,
    invalidEmail: `${errorsBackgroundFieldsKey}.INVALID_EMAIL`,
    invalidEmailDomain: `${errorsBackgroundFieldsKey}.INVALID_EMAIL_DOMAIN`,
    maxCharacters255: `${errorsBackgroundFieldsKey}.MAX_CHARACTERS_255`,
    differentRut: `${errorsBackgroundFieldsKey}.DIFFERENT_RUT`
  }
};

export const MIN_DATE = '1981-04-01';

export const WORK_SITUATION = {
  A: {
    title: `${backgroundFieldsKey}.WORK_SITUATION.OPTIONS.DEPENDENT_WORKER`,
    definitions: [{ title: `FORM_DEFINITIONS.1.TITLE`, definition: `FORM_DEFINITIONS.1.TEXT` }]
  },
  B: {
    title: `${backgroundFieldsKey}.WORK_SITUATION.OPTIONS.INDEPENDENT_WORKER`,
    definitions: [{ title: `FORM_DEFINITIONS.2.TITLE`, definition: `FORM_DEFINITIONS.2.TEXT` }]
  },
  C: {
    title: `${backgroundFieldsKey}.WORK_SITUATION.OPTIONS.UNEMPLOYED`,
    definitions: [{ title: `FORM_DEFINITIONS.3.TITLE`, definition: `FORM_DEFINITIONS.3.TEXT` }]
  },
};
